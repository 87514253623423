import { render, staticRenderFns } from "./userLabel1.vue?vue&type=template&id=b5a6c9ca&scoped=true"
import script from "./userLabel1.vue?vue&type=script&lang=js"
export * from "./userLabel1.vue?vue&type=script&lang=js"
import style0 from "./userLabel1.vue?vue&type=style&index=0&id=b5a6c9ca&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5a6c9ca",
  null
  
)

export default component.exports