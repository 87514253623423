<template>
    <div class="chart">
        <div class="chart-title">
            <img src="@/assets/aggroTrace/down.png" alt="">
            <span>产量统计</span>
        </div>
        <div class="echarts" id="echarts2"></div>
    </div>
</template>
<script>
import * as echarts from "echarts";
export default{
    data(){
        return{
            option:{
                color: ['#fda205', '#62d6fe'],
                title: {
                    show:false
                },
                grid:{
                    left: '10%',
                    right: '10%',
                    top:35,
                    bottom: 30
                },
                legend: {
                    show:true,
                    icon: 'rect',
                    itemWidth:10,
                    itemHeight:10,
                    textStyle:{
                        color:'#fff'
                    }
                },
                xAxis: {
                    data: ['Mon', 'Tue', 'Wed', 'Thu'],
                    axisLabel: {
                      color: '#fff'
                    },
                    axisTick: {
                      show: false
                    },
                    axisLine: {
                     show: true,
                     lineStyle:{
                        color: 'rgba(28,93,100,0.9)',
                      }
                    },
                    z: 10
                },
                yAxis: {
                    axisLine: {
                      show: false,
                    },
                    axisLabel: {
                       color: '#fff'
                    },
                    splitLine:{
                        lineStyle:{
                            color: 'rgba(255,255,255,0.3)',
                            type: 'dashed'
                        }
                    }
                },
                series: [
                    {
                        name: '2022',
                        type: 'line',
                        data: [120, 200, 150, 80, 70, 110, 130]
                    },
                    {
                        name: '2023',
                        type: 'line',
                        data: [110, 80, 100, 80, 70, 70, 50]
                    }
                ]
            }
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        init(){
            this.getOutput();

            
        },

      // 产量曲线
      getOutput(){
        this.$get('/digitalAgriculture/getDigitalAgricultureYield', {

        }).then((res) => {
          if(res.data.state == 'success'){
            let datas = res.data.data;
            let time = datas.years.reverse();
            let names = datas.farmProducts;
            let value = datas.productionValues;
            let dataAll = [];
            for(let a = 0; a < names.length; a++){
              dataAll.push({name: names[a], value: value[names[a]]});
            }
            console.log("dataAll:",dataAll)
            this.getEcharts2(dataAll, time)
          }
        })
      },

      getEcharts2(dataAll, time){
        var chartDom = document.getElementById('echarts2');
        var myChart = echarts.init(chartDom);
        let borderColor = ['#646ace', '#FFB000', '#ff7377', '#db43c7'];
        let serData = [];
        dataAll.forEach((item,index) => {
          serData.push({
            name: item.name,
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            lineStyle: {
              normal: {
                width: 3,
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              borderColor: borderColor[index],
              borderWidth: 2,
            },
            tooltip: {
              show: true,
            },
            data: item.value.reverse(),
          })
        })

        let option = {
          color: ['#5CFFDC', '#FFD24A', '#fbb1a2', '#4AA2FF'],
          tooltip: {
            trigger: 'axis',
            borderWidth: 2,
            padding: [5, 10],
          },
          legend: {
            align: 'center',
            // right: '10%',
            top: '0',
            textStyle: {
              color: '#fff',
              fontSize: 13,
              padding: [0, 50, 0, 20],
            },
            // itemGap: 30,
            itemWidth: 20,
            itemHeight: 10,

          },
          grid: {
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '18%',
            // containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              name: '',
              nameGap: 10,
              boundaryGap: false,
              nameTextStyle: {
                // ---坐标轴名称样式
                color: '#fff',
                fontSize: 12,
              },
              axisLabel: {
                show: true,
                interval: 0, //横轴信息全部显示
                // rotate:-30,//-30度角倾斜显示
                textStyle: {
                  color: '#fff', // 更改坐标轴文字颜色
                  fontSize: 12, // 更改坐标轴文字大小
                },
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#379E98AB',
                },
              },
              axisTick: {
                show: false,
              },
              data: time,
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: "产量：万斤",
              min: 0,
              nameTextStyle: {
                color: "#fff",
                fontSize: 12,
                padding: [20, 20, 0, 25]
              },
              axisLine: {
                lineStyle: {
                  color: '#999'
                }
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#999',
                  type: "dotted",
                }
              },
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#ccc", fontSize: 12,
                }
              },
            },
          ],
          series: serData
          // series: [
          //     {
          //         name: '小麦',
          //         type: 'line',
          //         symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
          //         showAllSymbol: true,
          //         symbolSize: 8,
          //         smooth: true,
          //         lineStyle: {
          //             normal: {
          //                 width: 3,
          //             },
          //             borderColor: 'rgba(0,0,0,.4)',
          //         },
          //         itemStyle: {
          //             borderColor: '#646ace',
          //             borderWidth: 2,
          //         },
          //         tooltip: {
          //             show: true,
          //         },
          //         data: goToSchool,
          //     },
          //     {
          //         name: '水稻',
          //         type: 'line',
          //         symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
          //         showAllSymbol: true,
          //         symbolSize: 8,
          //         smooth: true,
          //         lineStyle: {
          //             normal: {
          //                 width: 3,
          //             },
          //             borderColor: 'rgba(0,0,0,.4)',
          //         },
          //         itemStyle: {
          //             borderColor: 'yellow',
          //             borderWidth: 2,
          //         },
          //         tooltip: {
          //             show: true,
          //         },
          //         data: goOutSchool,
          //     },
          // ],
        };

        option && myChart.setOption(option);
        window.addEventListener("resize", function() {
          option && myChart.resize();
        });
      },
      //销售曲线
      getEcharts1(dataAll, time){
        var chartDom = document.getElementById('echarts2');
        var myChart = echarts.init(chartDom);
        let borderColor = ['#646ace', '#FFB000', '#ff7377', '#db43c7'];
        let serData = [];
        dataAll.forEach((item,index) => {
          serData.push({
            name: item.name,
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 8,
            smooth: true,
            lineStyle: {
              normal: {
                width: 3,
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              borderColor: borderColor[index],
              borderWidth: 2,
            },
            tooltip: {
              show: true,
            },
            data: item.value.reverse(),
          })
        })

        let option = {
          color: ['#5CFFDC', '#FFD24A', '#fbb1a2', '#4AA2FF'],
          tooltip: {
            trigger: 'axis',
            borderWidth: 2,
            padding: [5, 10],
          },
          legend: {
            align: 'center',
            // right: '10%',
            top: '0',
            textStyle: {
              color: '#fff',
              fontSize: 13,
              padding: [0, 50, 0, 20],
            },
            // itemGap: 30,
            itemWidth: 20,
            itemHeight: 10,

          },
          grid: {
            top: '30%',
            left: '10%',
            right: '10%',
            bottom: '18%',
            // containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              name: '',
              nameGap: 10,
              boundaryGap: false,
              nameTextStyle: {
                // ---坐标轴名称样式
                color: '#fff',
                fontSize: 12,
              },
              axisLabel: {
                show: true,
                interval: 0, //横轴信息全部显示
                // rotate:-30,//-30度角倾斜显示
                textStyle: {
                  color: '#fff', // 更改坐标轴文字颜色
                  fontSize: 12, // 更改坐标轴文字大小
                },
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#379E98AB',
                },
              },
              axisTick: {
                show: false,
              },
              data: time,
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: "产值：万元",
              min: 0,
              nameTextStyle: {
                color: "#fff",
                fontSize: 12,
                padding: [20, 20, 0, 25]
              },
              axisLine: {
                lineStyle: {
                  color: '#999'
                }
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#999',
                  type: "dotted",
                }
              },
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#ccc", fontSize: 12,
                }
              },
            },
          ],
          series: serData

        };

        option && myChart.setOption(option);
        window.addEventListener("resize", function() {
          option && myChart.resize();
        });
      },
    },
}
</script>
<style lang="scss" scoped>
.chart{
    width: 50%;
    height: 100%;
    &-title{
        height: 40px;
        background-color: rgba(20,103,105,0.4);
        margin: 10px 10%;
        display: flex;
        color: #fff;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        img{
            width: 35px;
            margin-right: 10px;
        }
    }
}
.echarts{
    width: 100%;
    height: calc(100% - 50px);
}
</style>