<template>
  <!--    编辑-->
  <el-dialog
      title="详情"
      :visible.sync="visiable"
      width="500px"
      class="addAlameBox"
      :close-on-click-modal="false"
      @close="handleCancel"
  >
    <div style="max-height: 65vh">
      <div class="list_top">
        <el-image
            class="headImg"
            :src="data.imgPath"
            fit="cover"
        ></el-image>
        <div class="itemMess">
          <div class="itemLine">
            <div style="min-width: 60px">成果名称:</div>
            <div>{{ data.goodsName }}</div>
          </div>

          <!--          <div class="itemLine">
                      <div style="min-width: 60px">类型:</div>
                      <div>{{ data.goodsType }}</div>
                    </div>-->
          <div class="itemLine">
            <div style="min-width: 60px">介绍:</div>
            <div>{{ data.introduce }}</div>
          </div>

          <div class="itemLine">
            <div style="min-width: 60px">归属人:</div>
            <div>{{ data.manufacturer }}</div>
          </div>


        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visiable: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {};
  },
  mounted() {
    // //console.log(this.record, "record");
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.list_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.itemMess {
  width: calc(100% - 120px);
}

.itemLine {
  width: 100%;
  display: flex;
  font-size: 14px;
  line-height: 24px;
}

.text_ell {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headImg {
  width: 100px;
  height: 100px;
}
</style>