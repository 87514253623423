import { render, staticRenderFns } from "./fatory.vue?vue&type=template&id=940cbf3a&scoped=true"
import script from "./fatory.vue?vue&type=script&lang=js"
export * from "./fatory.vue?vue&type=script&lang=js"
import style0 from "../../../style/font/font.css?vue&type=style&index=0&id=940cbf3a&prod&scoped=true&lang=css&external"
import style1 from "./fatory.vue?vue&type=style&index=1&id=940cbf3a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "940cbf3a",
  null
  
)

export default component.exports