<template>
  <!--    编辑-->
  <el-dialog
      :title="formTitle"
      :visible.sync="messageBox"
      width="70vw"
      class="addAlameBox"
      :close-on-click-modal="false"
  >
    <div style="max-height: 65vh;">
      <FormBox
          ref="formRef"
          :showFlag="showFlag"
          :formArr="formArr"
          :record="addForm"
          @saveForm="saveForm"
          @handleCancel="handleCancel"
          :fileListModify="fileListModify"
          :rules="rules"
      ></FormBox>
    </div>
  </el-dialog>
</template>

<script>
import FormBox from "@/components/lxw/Form/index.vue";

export default {
  components: {FormBox},
  props: {
    formTitle: {
      type: String,
      default: "新增",
    },
    messageBox: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      formArr: [
        {
          label: "成果名称",
          key: "goodsName",
          type: "Input",
        },
        /* {
           label: "类型",
           key: "goodsType",
           type: "Input",
         },*/
        {
          label: "介绍",
          key: "introduce",
          type: "Textarea",
        },

        {
          label: "归属人",
          key: "manufacturer",
          type: "Input",
        },
        {
          label: "图片",
          key: "imgPath",
          type: "FileUpload",
          valueType: 'string',
          width: "100%",
          fileType: ["jpg", "jpeg", "png"]
        }
      ],
      addForm: {},
      rules: {
        // 产品名称
        inputName: [
          {required: true, message: "名称不可为空", trigger: ["blur", "change"]},
        ],
        // 规格
        unit: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
      },
      fileListModify: []
    };
  },
  watch: {
    record: {
      handler(newVal, oldVal) {

        this.addForm = newVal;
        console.log("接收到111", this.addForm)

        if (newVal.imgPath) {
          this.fileListModify = [{name: newVal.imgPath, url: newVal.imgPath}];
        } else {
          this.fileListModify = [];
        }


      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    //console.log(this.record, "record");
  },
  methods: {
    getFileListAndForm(obj) {
      if (obj && obj.fileList) {
        const {fileList} = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
              item.response &&
              item.response.data &&
              item.response.data.fileName
          ) {
            list.push(item.response.data.fileName);
          }
        });
        this.filesList = list;
      }
    },

    // 确认提交
    saveForm(param) {

      let url = "/pro2501/goods/save";
      delete param.pushRowsBtn;
      if (!param.status) {
        param.status = 0;
      }
      // if(this.formTitle == '新增'){
      //   delete this.addForm.id;
      // }


      //商品
      param.goodsType = "成果"

      this.$postJSON(url, param).then((res) => {
        if (res.data.state == "success") {
          if (this.formTitle == "新增") {
            this.$message.success("添加成功");
          } else {
            this.$message.success("修改成功");
          }
          this.$emit("success");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 重置字段
    resetForm() {
      this.$refs["formRef"].resetForm();
    },

    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

</style>