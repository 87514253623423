<template>
  <div class="container" id="content">
    <TableBox
        ref="tableBox"
        :tableHeader="tableHeader"
        :rowBtns="rowBtns"
        request="/pro2501/prodplan/page?type=科研"
        @addOption="addOption"
    ></TableBox>

    <UpdataModal
        ref="updata"
        :showFlag="showFlag"
        :messageBox="messageBox"
        :formTitle="formTitle"
        @close="closeModal"
        @success="refresh"
        :record="addForm"
    ></UpdataModal>
  </div>
</template>
<script>
import UpdataModal from "./modal/updata.vue";
import TableBox from "@/components/lxw/TableBox/index.vue";

export default {
  name: "lawsAReg",
  components: {UpdataModal, TableBox},
  data() {
    return {
      loading: false,
      ids: 0,
      addForm: {},

      tableHeader: [
        {
          label: "地理位置",
          key: "origin",
        },
        {
          label: "项目名称",
          key: "productName",
        },
        {
          label: "检验数量",
          key: "quality",
        },
        /*{
          label: "计量单位",
          key: "unit",
        },

        {
          label: "开始日期",
          key: "beginDate",
        },
        {
          label: "结束日期",
          key: "endDate",
        },
        {
          label: "负责人",
          key: "curator",
        },
        {
          label: "企业",
          key: "manufacturer",
        },*/
      ],
      rowBtns: [
        {
          label: "编辑",
          type: "primary",
          size: "small",
          icon: "el-icon-edit",
          handler: (row) => {
            this.edits(row);
          },
        },
        {
          label: "删除",
          type: "danger",
          size: "small",
          icon: "el-icon-delete",
          handler: (row) => {
            this.deletes(row.id);
          },
        },
      ],

      formTitle: "",
      messageBox: false,
      showFlag: false,
      token: "",
      filesList: [],
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem("token"));
  },
  methods: {
    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      row.ncpxxId = parseInt(row.ncpxx_id);
      this.addForm = JSON.parse(JSON.stringify(row));
      this.messageBox = true;
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$delete(`/pro2501/prodplan/delete/${id}`).then((res) => {
              if (res.data.state == "success") {
                this.$message.success("删除成功");
                this.$refs.tableBox.getList();
              } else {
                this.$message.error("删除失败");
              }
            });
          })
          .catch((e) => e);
    },

    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;

      this.$nextTick(() => {
        this.addForm = {
          // title: 'biaoti'
        };
        this.$refs.updata.resetForm();
      });
    },

    refresh() {
      this.messageBox = false;
      this.$refs.tableBox.getList();
    },

    closeModal() {
      this.messageBox = false;
    },
  },
};
</script>

<style scoped>
</style>
