<template>
  <div style="position: relative">
    <div style="position: absolute; top: 6vh; left: 15vw; width: 50%">
      <el-form ref="form" label-width="120px">
        <h3 style="text-align: left;margin: 20px 0;">APP二维码</h3>
        <img src="@/assets/app.png" width="200" alt="">
        <div style="height: 30px"></div>
        <h3 style="text-align: left;margin: 20px 0;">小程序码</h3>

        <img src="@/assets/mini.jpg" width="200" alt="">

      </el-form>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},

    };
  },
  mounted() {
  },
  methods: {},
};
</script>
