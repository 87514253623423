import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
// HighCharts插件
// import VueHighCharts from "vue-highcharts";
// 地图
import AMap from "vue-amap";
//导入导出excel组件
import JsonExcel from "vue-json-excel";
// pdf
import getPdf from '@/components/addRecord'
// 导入富文本编辑器
import E from "wangeditor";
// 字体样式
import '@/style/font/font.css'
// 导入组件element
import { Message, MessageBox } from "element-ui";
import ElementUI from "element-ui";
// import Notifications from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 引入echarts
// import echarts from "echarts";
import * as echarts from 'echarts'
import 'echarts-liquidfill'
//播放m3n8格式
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";
// 阿里矢量图标
//import "./assets/iconfont/iconfont.css";
import "./assets/iconfont/font.css";
// 引入和风天气图标
//import 'qweather-icons/font/qweather-icons.css';
// 引入样式
import "./style/bigdata.css";

import "amfe-flexible"
import dataV from '@jiaminghi/data-view'

// loading
import { startLoading, endLoading } from './utils/loading'
import {
    requrstPostJson,
    requrstPostLogin,
    requrstGetJson,
    requrstPost,
    requrstGet,
    requrstGetBlob,
    upImgs,
    logisticGet,
    userServer,
    requrstGui,
    requrstDel, requrstPut
} from "../src/request/api/interface";
//导入leaflet样式
import "leaflet/dist/leaflet.css";
Vue.config.productionTip = false;
import * as L from "leaflet";
Vue.L = Vue.prototype.$L = L;
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

/*import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsDrilldown from "highcharts/modules/drilldown";
import Highcharts3D from "highcharts/highcharts-3d";
 
HighchartsMore(Highcharts);
HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);*/

/**
  * select 下拉框 底部触发指令
 */
Vue.directive('selectLoadMore',{
    bind (el, binding) {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
      SELECTWRAP_DOM.addEventListener('scroll', function () {
        if (this.scrollHeight - this.scrollTop < this.clientHeight + 1) {
          binding.value()
        }
      })
    }
  })

// 导入qrcode二维码
import QRCode from 'qrcodejs2'
/* AMap.initAMapApiLoader({
    key: "23d95d6620fa5bd2e8da3f61be5de237",
    plugin: [
        "AMap.Autocomplete",
        "AMap.PlaceSearch",
        "AMap.Scale",
        "AMap.OverView",
        "AMap.ToolBar",
        "AMap.MapType",
        "AMap.PolyEditor",
        "AMap.CircleEditor",
        "AMap.Geolocation",
        "Geolocation",
        "AMap.DistrictSearch",
    ],
    v: "1.4.4",
}); */

// 引入notification
// import Notifications from "vue-notification"

import horizontalScroll from 'el-table-horizontal-scroll'
Vue.use(horizontalScroll)
Vue.use(dataV)
import dataSelect from "@/components/breadCrumb/dataSelect.vue"
Vue.component('dataSelect', dataSelect)
// 安装jsonp插件
import { VueJsonp } from "vue-jsonp";
Vue.use(VueJsonp);
Vue.use(getPdf)
    // 挂载到实例
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$axios = axios;
// Vue.prototype.$vueHighCharts = VueHighCharts;
Vue.prototype.$echarts = echarts;
Vue.prototype.$delete = requrstDel;
Vue.prototype.$post = requrstPost;
Vue.prototype.$put = requrstPut;
Vue.prototype.$postJSON = requrstPostJson;
Vue.prototype.$postLogin = requrstPostLogin;

Vue.prototype.$getJSON = requrstGetJson;
Vue.prototype.$get = requrstGet;
Vue.prototype.$getBlob = requrstGetBlob;
Vue.prototype.$gui = requrstGui;
Vue.prototype.$userServer = userServer;
Vue.prototype.$upImgs = upImgs;
Vue.prototype.$logisticGet = logisticGet;
Vue.prototype.$startLoading = startLoading;
Vue.prototype.$endLoading = endLoading;
Vue.prototype.$window = window;
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
