<template>
  <div class="container" id="content">

    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">

            <el-form-item label="" style="margin-left: 55vw">
              <el-input v-model="searchName" placeholder="请输入设备名称"></el-input>
              <el-input v-model="searchNum" placeholder="请输入设备编号"></el-input>
            </el-form-item>
            <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
          </el-form>
        </div>

      </div>
    </div>
    <div class="bodyInfo">
      <el-table :data="tableData" style="width: 100%" empty-text="暂无数据" ref="filterTable"
                :default-sort="{ prop: 'date2', order: 'descending' }">
        <el-table-column show-overflow-tooltip label="序号" align="center" :index="indexMethod" width="100"
                         type="index"></el-table-column>
        <el-table-column prop="deviceType" label="设备类型" show-overflow-tooltip column-key="deviceType" :filters="[
          { text: '气象监测设备', value: 1 },
          { text: '土壤墒情设备', value: 3 },
          { text: '水质监测设备', value: 4 },
        ]" :filter-method="filterHandler" :filter-multiple="true" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.deviceType == 1">气象监测设备</span>
            <span v-if="scope.row.deviceType == 3">土壤墒情设备</span>
            <span v-if="scope.row.deviceType == 4">水质监测设备</span>
            <span></span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceName || "暂无" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceNum" label="设备编号" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceNum || "暂无" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="onLineState" label="在线状态" show-overflow-tooltip align="center" :formatter="onLineForm">
        </el-table-column>
        <el-table-column prop="brand" label="品牌" show-overflow-tooltip column-key="brand" :filters="[
          { text: '协议2.0', value: 1 },
          { text: '精讯云', value: 0 },
          { text: '协议3.0', value: 2 },
        ]" :filter-method="filterHandler" :filter-multiple="true" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.brand == 1">协议2.0</span>
            <span v-if="scope.row.brand == 0">精讯</span>
            <span v-if="scope.row.brand == 2">协议3.0</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceTemp" label="绑定模板" show-overflow-tooltip align="center" :formatter="tempFormat">

        </el-table-column>
        <el-table-column prop="realData" label="设备数据" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{
                scope.row.realData ? scope.row.realData.replace(/null\|/g, '').replace(/\|null/g, '') : "暂无"
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="realTime" label="更新时间" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.realTime || "暂无" }}</span>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <div class="pageBox">
      <el-pagination layout="sizes, prev, pager, next, jumper" :total="dataTotal" :current-page="pageNow"
                     :page-sizes="[10, 20, 50,100]" :page-size="pageSize" @size-change="siceChange"
                     @current-change="currentChange">
      </el-pagination>
    </div>

    <!-- 新增 编辑 -->
    <el-dialog :title="eachTitle" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="formContainter">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="设备名称" prop="deviceName">
            <el-input v-model="deviceName"></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="deviceNum">
            <el-input :disabled="disable" v-model="ruleForm.deviceNum"></el-input>
          </el-form-item>
          <el-form-item label="设备类型" prop="deviceType">
            <el-select v-model="ruleForm.deviceType" placeholder="请选择设备类型">
              <el-option label="气象监测设备" value="1"></el-option>
              <el-option label="控制设备" value="2"></el-option>
              <el-option label="土壤墒情设备" value="3"></el-option>
              <el-option label="水质监测设备" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品牌" prop="brand">
            <el-select v-model="ruleForm.brand" placeholder="请选择设备品牌">
              <el-option label="协议2.0" value="1"></el-option>
              <el-option label="精讯云" value="0"></el-option>
              <el-option label="协议3.0" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板绑定" prop="deviceTemp">
            <el-select value-key="id" v-model="tempSet" placeholder="请选择模板">
              <el-option :label="item.templateName" :value="item.id" v-for="item in templateList" :key="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="设备绑定用户" prop="deviceUser">
                      <el-select value-key="id" v-model="valueSet" placeholder="请选择用户" @change="changeSet">
                        <el-option v-for="item in setList" :key="item.id" :label="item.userName" :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>-->
          <el-form-item label="设备地址" prop="data7">
            <el-input v-model="data7"></el-input>
          </el-form-item>
          <!-- <div id="mapBox" class="mapBox"></div> -->
          <set-Map ref="mapPosition" :center="mapCenter" @getDatas="getDatas"></set-Map>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 分配 -->
    <el-dialog title="分配用户" :visible.sync="allocationVisible" width="40%">
      <el-form :model="allocateForm" label-width="110px">
        <el-form-item label="设备名称:">
          {{ allocateForm.deviceName }}
        </el-form-item>
        <el-form-item label="设备编号:">
          {{ allocateForm.deviceNum }}
        </el-form-item>
        <el-form-item label="分配用户:">
          <el-select multiple v-model="userIds" placeholder="请选择分配用户" v-selectLoadMore="selectLoadMore">
            <el-option v-for="(item,index) in setList" :key="index" :label="item.userName" :value="item.id"></el-option>
            <div v-loading="userLoading" element-loading-spinner="el-icon-loading"></div>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table :data="allocateUserList" style="width: 100%">
        <el-table-column type="index" align="center" width="80" :index="indexMethod" label="序号"></el-table-column>
        <el-table-column prop="userName" align="center" label="用户名"></el-table-column>
        <el-table-column prop="realName" align="center" label="账号"></el-table-column>
        <el-table-column prop="createTime" align="center" width="110" label="分配日期"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="allocateCancel(scope.row)">取消分配</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="allocationVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAllocateSave">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import getBeforeMonth from "../../../components/getBeforeMonth.js";
import setMap from "../../../components/setMap.vue";
/*import initMarkerMap from "../../../components/initMarkerMap.vue";*/
export default {
  components: {setMap},
  data() {
    const checkPosition = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("不可为空"));
      }
      var lon = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/;
      var lat = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/;
      var lonRe = new RegExp(lon);
      var latRe = new RegExp(lat);
      let lon1 = value.split(",")[0];
      let lat1 = value.split(",")[1];
      setTimeout(() => {
        if (lon1 == '' || !lonRe.test(value.split(",")[0])) {
          callback(new Error("经度不符合规范：经度整数部分为0-180,小数部分为0-6位！"));
        } else if (lat1 == '' || !latRe.test(value.split(",")[1])) {
          callback(new Error("纬度不符合规范：纬度整数部分为0-90,小数部分为0-6位！"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      mapCenter: [],
      temDevice: '',
      templateList: [],
      isTemplate: false,
      disable: false,
      htmlTitle: "",
      valueStart: "",
      valueEnd: "",
      deviceData: [],
      setData: [],
      childShow: true,
      valueSet: "",
      tempSet: "",
      // 基地列表
      setList: [],
      userPage: 1,
      userPageLast: false,
      userLoading: false,
      dataTotal: 0,
      // 曲线时间选择器
      value1: [],
      drawer: false,
      eachTitle: "新增",
      dialogVisible: false,

      // 头部搜索标志
      isShow: false,
      // 当前页
      pageNow: 1,
      // 当前页数
      pageSize: 100,
      tableData: [],
      ruleForms: {
        id: ''
      },
      ruleTem: {
        id: [{required: true, message: "不可为空", trigger: "change"}],
      },
      // 表单数据
      ruleForm: {
        deviceType: "", //1气象,2墒情设备类型
        deviceTemp: "", //模板
        // greenName: "", //归属基地名字
        // usernames: "", //绑定用户
        // tel: "", //手机号
        //deviceUser: "",//绑定用户
        types: 2,
        deviceNum: "",//设备编号
        deviceName: "",//设备名
        data7: "",//地图标点
      },
      data7: "",
      deviceName: "",
      searchName: '',
      searchNum: '',
      rules: {
        deviceName: [{required: true, message: "不可为空", trigger: "change"}],
        deviceType: [{required: true, message: "不可为空", trigger: "change"}],
        deviceTemp: [{
          required: true, trigger: "change", validator: (rule, value, callback) => {
            if (this.tempSet == '') {
              callback(new Error("不可为空"));
            } else {
              callback();
            }
          }
        }],
        deviceUser: [{
          required: true, trigger: "change", validator: (rule, value, callback) => {
            if (this.valueSet == '') {
              callback(new Error("不可为空"));
            } else {
              callback();
            }
          }
        }],
        usernames: [{required: true, message: "不可为空", trigger: "change"}],
        tel: [
          {required: true, message: "不可为空", trigger: "change"},
          {
            validator: function (rule, value, callback) {
              if (/^1[34578]\d{9}$/.test(value) == false) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        types: [{required: true, message: "不可为空", trigger: "change"}],
        // data7: [{ required: true, validator: checkPosition, trigger: "change" }],
        longitude: [{required: true, message: "不可为空", trigger: "change"}],
        deviceNum: [
          {required: true, message: "不可为空", trigger: "change"},
        ],
        // deviceName: [{ required: true, message: "不可为空", trigger: "blur" }],
        devicePassword: [
          {required: true, message: "不可为空", trigger: "change"},
        ]
      },
      index: 0,
      allocationVisible: false,
      allocateForm: {
        deviceName: '',
        deviceNum: ''
      },
      userIds: '',
      allocateUserList: [],
      deviceId: null
    };
  },
  created() {
    this.getTitle();
    this.getList();
    this.getSetList();
    this.getTemplateList();//模板列表
  },
  mounted() {
  },
  watch: {
    deviceName(newVal, oldVal) {
      this.ruleForm.deviceName = newVal;
      this.$forceUpdate();
    },
  },
  methods: {
    getTitle() {
      this.$get("/largeScreenManagement/getLargeScreenManagements", {
        page: 1,
        size: 100
      }).then((res) => {
        if (res.data.state == 'success') {
          this.mapCenter = res.data.datas[0].location.split(",")
          //console.log(this.mapCenter)
        }
      })
    },
    //刷新分配列表
    async getAllocateLsit(deviceNum) {
      const res = await this.$get("/deviceManage/getUsers/" + deviceNum)
      this.allocateUserList = res.data.datas
      this.userIds = res.data.datas.map(v => v.id)
    },
    allocateCancel(row) {
      this.$postJSON('/deviceManage/cancelUser', {
        deviceId: this.deviceId,
        userIds: [row.id]
      }).then(async res => {
        if (res.data.state == 'success') {
          this.getAllocateLsit(this.allocateForm.deviceNum)
          this.$message.success('取消分配成功')
        }
      })
    },
    async allocateUsers(row) {
      this.allocateForm = {
        deviceName: row.deviceName,
        deviceNum: row.deviceNum
      }
      this.deviceId = row.id
      this.getAllocateLsit(row.deviceNum)
      this.allocationVisible = true

    },
    // 分配保存
    handleAllocateSave() {
      if (this.userIds && this.userIds.length == 0) {
        this.$message({
          message: '分配用户不能为空！',
          type: 'error'
        });
        return
      }
      this.$postJSON('/deviceManage/distributeUser', {
        deviceId: this.deviceId,
        userIds: this.userIds
      }).then(res => {
        if (res.data.state == 'success') {
          // this.getList();
          this.allocationVisible = false
          this.$message.success('分配成功')
        }
      })
    },
    // 下载报表
    getDown() {
      this.getPdf(this.htmlTitle);
    },
    // 序号
    indexMethod(index) {
      return index + this.pageSize * (this.pageNow - 1) + 1;
    },
    // 时间选择
    changeTime(e) {
      this.valueStart = e[0];
      this.valueEnd = e[1];
      this.getEchartsData();
    },
    // 获取echarts图表数据
    getEchartsData() {
      let xData = [];
      let yData = [];
      this.$get("/device/getDeviceDataStart", {
        deviceNumber: this.devices,
        startTime: this.valueStart,
        endTime: this.valueEnd,
      })
          .then((res) => {
            let data = res.data.data;
            if (res.data.state != "failed") {
              //console.log(data);
              if (data.data && data.date && data.date[0]) {
                xData = data.date;
                for (var key in data.data) {
                  yData.push({name: key, values: data.data[key]});
                }
              } else {
                xData = [0];
                yData = [{name: "暂无", values: 0}];
              }
            } else {
              xData = [0];
              yData = [{name: "暂无", values: 0}];
            }
            this.lineCanvased(xData, yData);
          })
          .catch((error) => {
            xData = [0];
            yData = [{name: "暂无", values: 0}];
            this.lineCanvased(xData, yData);
          });
    },
    selectLoadMore() {
      if (this.userPageLast) return
      this.userPage += 1
      this.getSetList()
    },
    //   获取用户列表
    async getSetList() {
      this.userLoading = true
      const {data} = await this.$get("/userManage/getUserList", {
        page: this.userPage,
        size: 100,
        // name: "",
        // id: "",
      });
      this.userLoading = false
      this.userPageLast = data.isLast
      this.setList = this.setList.concat(data.datas);
    },
    changeSet(val) {
      //console.log(val);
      this.ruleForm.greenName = val.name;
      this.ruleForm.greenId = val.id;
    },
    //搜索
    search() {
      this.pageNow = 1
      this.getList();
    },
    //   获取列表
    async getList() {
      this.loading = true;
      const {data} = await this.$get("/deviceManage/getDeviceInfos", {
        page: this.pageNow,
        size: this.pageSize,
        deviceNum: this.searchNum ? this.searchNum : '',
        deviceName: this.searchName ? this.searchName : '',
        deviceType: "1,3,4",
      });
      var datas = data.datas;
      this.tableData = datas.filter(item => item.deviceType !== "2");
      console.log("设备列表：", this.tableData)
      this.dataTotal = data.count;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    // 获取曲线图
    lineCanvased(xData, yData) {
      this.$echarts.init(this.$refs.lineCanvas).dispose();
      let seriesArr = [];
      const hexToRgba = (hex, opacity) => {
        let rgbaColor = "";
        let reg = /^#[\da-f]{6}$/i;
        if (reg.test(hex)) {
          rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
              "0x" + hex.slice(3, 5)
          )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
        }
        return rgbaColor;
      };
      let color = [
        "#8B5CFF",
        "#00CA69",
        "green",
        "#99FF00",
        "#7B68EE",
        "#ff509b",
        "#4169E1",
        "#c3930b",
        "#00FFFF",
        "#0073c1",
        "#fde7e3",
        "#330066",
        "#336699",
        "#990033",
        "#FFFF66",
      ];
      yData.forEach((item, z) => {
        seriesArr.push({
          // name: "2018",
          name: item.name,
          type: "line",
          smooth: true,
          symbolSize: 8,
          zlevel: 3,
          lineStyle: {
            normal: {
              color: color[z],
              shadowBlur: 3,
              shadowColor: hexToRgba(color[z], 0.5),
              shadowOffsetY: 8,
            },
          },
          symbol: "circle", //数据交叉点样式
          data: item.values,
        });
      });

      const mychart = this.$echarts.init(this.$refs.lineCanvas);
      let option = {
        color: color,
        legend: {
          top: 20,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let html = "";
            params.forEach((v) => {
              html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]
              };"></span>
                ${v.seriesName}${v.name}
                <span style="color:${color[v.componentIndex]
              };font-weight:700;font-size: 18px;margin-left:5px">${v.value
              }</span>
                `;
            });
            return html;
          },
          extraCssText:
              "background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "#ffffff",
              shadowColor: "rgba(225,225,225,1)",
              shadowBlur: 5,
            },
          },
        },
        grid: {
          top: 100,
          bottom: "10%",
          left: "3%",
          right: "8%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#333",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#D9D9D9",
              },
            },
            data: xData,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            axisLabel: {
              textStyle: {
                color: "#666",
              },
            },
            nameTextStyle: {
              color: "#666",
              fontSize: 12,
              lineHeight: 40,
            },
            // 分割线
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#E9E9E9",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: seriesArr,
      };
      mychart.setOption(option);
    },
    // 详情抽屉关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
          .then((_) => {
            done();
          })
          .catch((_) => {
          });
    },
    // 详情
    getInfo(val) {
      //console.log(val)
      this.$get(`/deviceManage/getDeviceInfo/${val.id}`).then((res) => {
        //console.log(res);
      })
      this.htmlTitle = val.name;
      this.setData = [{lng: val.longitude, lat: val.latitude}];
      this.childShow = false;
      this.$nextTick(() => {
        this.childShow = true;
      });
      this.drawer = true;

      // 详情数据
      this.deviceData = [];
      let dataValue = [];
      let dataName = [];
      // 只有一组数据的情况
      if (val.data && val.data.indexOf("|") == -1) {
        this.deviceData[0].name = val.data;
        if (val.type && val.type.indexOf("|") == -1) {
          this.deviceData[0].value = val.type;
        } else {
          this.deviceData[0].value = "暂无";
        }
      } else {
        this.deviceData = [];
      }
      // 多组数据
      if (val.data && val.data.indexOf("|") > -1) {
        this.deviceData = [];
        dataValue = val.data.split("|");
        if (val.type && val.type.indexOf("/") > -1) {
          dataName = val.type.split("/");
          dataValue.forEach((item, j) => {
            this.deviceData.push({
              name: dataName[j] || "暂无",
              value: item,
            });
          });
        }
      }
      this.$nextTick(() => {
        this.devices = val.deviceNumber;
        let dayNum = getBeforeMonth(20);
        this.valueStart = dayNum[0];
        this.valueEnd = dayNum[1];
        this.value1 = [this.valueStart, this.valueEnd];
        this.getEchartsData();
      });
    },
    // 获取地图组件数据
    getDatas(e) {
      if (e == '' || e == null || e == undefined) {
        this.ruleForm.data7 = this.data7;
        this.ruleForm.longitude = this.data7.split(",")[0];
        this.ruleForm.latitude = this.data7.split(",")[1];
      } else {
        this.ruleForm.data7 = e;
        this.data7 = e;
        this.ruleForm.longitude = e.split(",")[0];
        this.ruleForm.latitude = e.split(",")[1];
      }
      this.$forceUpdate();
    },


    // 表单提交事件
    submitForm(formName) {
      let params = {};
      params.deviceType = this.ruleForm.deviceType;//类型
      params.deviceTemp = this.tempSet;//模板
      // params.deviceUser = this.valueSet.userName;
      // params.userLevel = this.valueSet.level;

      params.deviceNum = this.ruleForm.deviceNum;
      params.deviceName = this.deviceName;
      params.brand = this.ruleForm.brand;

      let urled = "";
      if (this.ruleForm.data7 == "") {
        params.deviceLocation = this.data7;
      } else {
        params.deviceLocation = this.ruleForm.data7;
      }
      //console.log(params);
      //console.log(this.ruleForm.data7);
      //console.log(this.data7);
      if (this.eachTitle == "新增") {
        urled = "/deviceManage/addOrUpdateDeviceInfo";
      } else if (this.eachTitle == "编辑") {
        urled = "/deviceManage/addOrUpdateDeviceInfo";
        params.deviceLocation = this.data7;
        params.id = this.ruleForm.id;
      }
      //console.log("打印");
      //console.log(urled);
      // this.ruleForm
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$postJSON(urled, params
          ).then((res) => {
            if (res.data.state == 'success') {
              if (this.formTitle == '新增') {
                this.$message.success('添加成功')
              } else {
                this.$message.success('修改成功')
              }
              this.dialogVisible = false;
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    // 新增弹窗关闭事件
    handleClose(done) {
      // 销毁地图
      done();
    },
    // 表头过滤事件
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    // 获取模板列表
    async getTemplateList() {
      const {data: {datas}} = await this.$get('/sensorTemp/page', {
        page: 1,
        size: 1000,
        // name: ''
      })
      this.templateList = datas;
    },
    // 提交绑定模板事件
    submitForms(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post('/template/changeDeviceTemplate', {
            deviceNumber: this.temDevice,
            id: this.ruleForms.id
          }).then((res) => {
            if (res.data.state == 'success') {
              this.$message.success('操作成功')
              setTimeout(() => {
                this.isTemplate = false;
              }, 200)
            } else {
              this.$message.error(res.data.msg)
              setTimeout(() => {
                this.isTemplate = false;
              }, 200)
            }
          }).catch((error) => {
            this.$message.error(error)
            setTimeout(() => {
              this.isTemplate = false;
            }, 200)
          })


        }
      })
    },
    // 设备绑定模板
    changeTemplate(val) {
      this.ruleForms.id = '';
      this.temDevice = val.deviceNumber;
      this.isTemplate = true;
      if (val.templateId) {
        let ids = val.templateId;
        this.ruleForms.id = this.templateList.find(item => item.id == ids).id ? this.templateList.find(item => item.id == ids).id : '';
        this.$forceUpdate();
      }
      // this.deviceNumberTem=JSON.parse(JSON.stringify(val)).templateId
    },
    // 删除实例
    // delCase(num) {
    //   this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$get("/device/delDeviceUser", { deviceNumber: num }).then(
    //         (res) => {
    //           if (res.data.state == "success") {
    //             this.$message.success("删除成功");
    //             if (this.pageSize == this.dataTotal * 1 - 1) {
    //               this.pageNow = 1;
    //             }
    //             this.getList();
    //           } else {
    //             this.$message(res.data.msg);
    //           }
    //         }
    //       );
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    delCase(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$delete(`/deviceManage/delDeviceInfoById/${id}`).then((res) => {
              if (res.data.state == "success") {
                this.$message.success("删除成功");
                this.getList();
              } else {
                this.$message.error("删除失败");
              }
            });
          })
          .catch((e) => e);
    },


    // 编辑事件
    editDeviceCase(val) {
      this.eachTitle = "编辑";
      this.disable = true;
      let _this = this;
      let data = JSON.parse(JSON.stringify(val));
      let longitu
      let latitu
      if (data.deviceLocation) {
        longitu = data.deviceLocation.split(',')[0];
        latitu = data.deviceLocation.split(',')[1];
        setTimeout(() => {
          _this.$refs["ruleForm"].clearValidate();
          // this.$set(this.ruleForm, 'data7', longitu + "," + latitu);
          this.$refs.mapPosition.setMap1(longitu, latitu);
        }, 30);
      }
      // //console.log(longitu,latitu);

      this.ruleForm = data;
      this.data7 = data.deviceLocation;
      this.deviceName = data.deviceName;
      this.ruleForm.deviceName = data.deviceName;
      this.ruleForm.deviceNum = data.deviceNum;
      this.ruleForm.data7 = this.data7;
      this.ruleForm.id = data.id;
      // this.ruleForm.data7 = this.data7;
      this.ruleForm.deviceType = data.deviceType + "";
      this.valueSet = data.deviceUser;
      this.tempSet = data.deviceTemp;
      this.dialogVisible = true;
    },
    // 添加事件
    addDeviceCase() {
      this.valueSet = "";
      this.eachTitle = "新增";
      this.disable = false;
      let _this = this;
      this.tempSet = "";
      setTimeout(() => {
        _this.$refs["ruleForm"].clearValidate();
        this.$refs.mapPosition.setMap1(this.mapCenter[0], this.mapCenter[1]);
      }, 30);
      for (var key in this.ruleForm) {
        this.ruleForm[key] = "";
      }
      this.deviceName = "";
      this.data7 = "";
      this.dialogVisible = true;
    },
    // 当前页数
    siceChange(val) {
      this.pageSize = val;
      // this.pageNow = 1;
      this.getList();
    },
    // 当前页
    currentChange(val) {
      this.pageNow = val;
      this.getList();
    },

    tempFormat(item) {
      let name = item.deviceTemp;
      let tempData = this.templateList;
      for (let i = 0; i < tempData.length; i++) {
        if (tempData[i].id == name) {
          return tempData[i].templateName;
        }
      }
    },
    onLineForm(item) {
      let realTime = item.realTime;
      if (new Date().getTime() - new Date(realTime).getTime() <= 18000000) {
        return '在线'
      } else {
        return '离线'
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#pdfDom {
  width: 100%;
  height: 100%;
}

.mainPartInfo {
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 4vh 2vw;
  box-sizing: border-box;
}

.mainPartInfo .headInfo {
  margin-bottom: 2vh;
}

.mainPartInfo .bodyInfo {
  max-height: 95%;
}

.mainPartInfo /deep/ .el-button {
  padding: 1vh 1vw;
}

.mainPartInfo .colBtns {
  color: #07ca88;
  margin-right: 0.5vw;
  cursor: pointer;
}

.mainPartInfo /deep/ .el-table {
  max-height: 70vh;
  overflow-y: scroll;
}

.mainPartInfo /deep/ .el-table th {
  background: #fafafa;
  box-shadow: 1px 1px 2px #fafafa;
}

.mainPartInfo /deep/ .el-table th > .cell:nth-of-type(-n + 1) {
  border-right: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageBox {
  position: fixed;
  right: 5vh;
  bottom: 5vh;
}

.mainPartInfo /deep/ .el-table__column-filter-trigger {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 0.5vh 0.5vw;
  line-height: 100%;
  float: right;
  background: url("../../../assets/twoBack/device3.png") no-repeat center/100% 100% !important;
}

.mainPartInfo /deep/ .el-icon-arrow-down:before {
  content: "" !important;
  background: url("../../../assets/twoBack/device3.png") no-repeat center/100% 100% !important;
}

.mainPartInfo /deep/ .el-table .caret-wrapper {
  float: right;
}

.mainPartInfo .headStyles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.mainPartInfo .headStyles p {
  width: 50%;
}

.mainPartInfo /deep/ .el-input__inner:hover {
  border-color: #07ca88;
}

.mainPartInfo /deep/ .el-input__inner:focus {
  border-color: #07ca88;
}

.mainPartInfo .mapBox {
  width: 100%;
  height: 30vh;
  margin-bottom: 2vh;
  border-radius: 30px;
}

.mainPartInfo .drawerBox {
  padding: 1vh 1vw;
  box-sizing: border-box;
  color: #606266;
  font-size: 1.6vh;
  word-break: break-all;
}

.mainPartInfo /deep/ .el-drawer__header {
  padding: 13px 20px 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6 !important;
  margin: 0 !important;
  font-size: 1.6vh;
}

.watchData {
  height: 20vh;
  width: 100%;
  border-bottom: 1px solid #dcdfe6;
  overflow: hidden;
}

.watchData h4 {
  font-size: 1.6vh;
  line-height: 1.5em;
  margin: 1vh 0;
}

.watchData ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 70%;
  overflow-y: scroll;
}

.watchData ul::-webkit-scrollbar {
  display: none;
}

.watchData ul li {
  width: 50%;
  line-height: 1.8em;
  font-size: 1.4vh;
}

.watchData ul li span {
  display: inline-block;
  margin-left: 0.5vw;
}

#lineCanvas {
  width: 100%;
  height: 35vh;
}

.pickerBox {
  width: 100%;
  margin-bottom: 2vh;
}

.downloadedBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.downloaded {
  font-size: 1.4vh;
  margin-right: 1vw;
  cursor: pointer;
}

.el-title-item .el-form {
  display: flex;
  align-items: center;
}

/deep/ .el-form-item__content {
  display: flex;
}

/deep/ .el-dialog__body {
  height: 500px;
  overflow: auto
}

</style>
