<template>
  <div ref="pie3d" class="pie3d"></div>
</template>
<script>
// echarts 立体饼图
// npm install --save echarts-gl
// https://blog.csdn.net/qq_43086723/article/details/136652188
import 'echarts-gl'
export default {
  props: {
    pieData: {
      type: Object,
      default: () => { },
    }
  },
  data() {
    return {
      itemStyleCokor: ['rgba(41,216,240,.8)', 'rgba(108,215,112,.8)', 'rgba(223,239,67,.8)'],
    }
  },
  watch: {
    pieData: {
      handler(newVal, oldVal) {
        this.init()
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    //初始化构建
    init() {
      let myChart = this.$echarts.init(this.$refs.pie3d);
      console.log(this.pieData, "00000000000000")
      let optionData = [
        {
          "code": "0",
          "name": "出库",
          "value": this.pieData.outwsCapacity || 0,
          "startRatio": 0,
          "endRatio": 0,
        },
        {
          "code": "1",
          "name": "入库",
          "value": this.pieData.inwsCapacity || 0,
          "startRatio": 0,
          "endRatio": 0,
        },
        {
          "code": "2",
          "name": "剩余",
          "value": this.pieData.unusedCapacity || 0,
          "startRatio": 0,
          "endRatio": 0,
        }
      ]
      this.option = this.getPie3D(optionData, 1.1);//柱子厚度
      console.log(this.option, "==================")
      myChart.setOption(this.option, true);
      this.$emit('getHeatComplain', myChart)
    },
    // 3D配置
    getPie3D(pieData, internalDiameterRatio) {
      console.log(pieData, internalDiameterRatio, "*********")
      let that = this;
      let series = [];
      let sumValue = 0;
      let startValue = 0;
      let endValue = 0;
      let legendData = [];
      let legendBfb = [];
      let k = 1 - internalDiameterRatio;
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value;
        let seriesItem = {
          type: 'surface',
          name: pieData[i].name,
          parametric: true,
          wireframe: {
            show: false
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k: k
          },
        };
        seriesItem.itemStyle = {
          color: this.itemStyleCokor[i],
          opacity: 1
        };
        series.push(seriesItem);
      }
      legendData = [];
      legendBfb = [];
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value;
        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = this.getParametricEquation(series[i].pieData.startRatio, series[i].pieData.endRatio,
          false, false, k, series[i].pieData.value);
        startValue = endValue;
        let bfb = that.fomatFloat(series[i].pieData.value / sumValue, 4);
        legendData.push({
          name: series[i].name,
          value: bfb,
          textStyle: { color: '#fff', fontSize: 13 }
        });
        legendBfb.push({
          name: series[i].name,
          value: bfb
        });
      }
      console.log(series, "##################")
      let boxHeight = this.getHeight3D(series, 15);
      let option = {
        legend: {
          data: legendData,
          bottom: 0,
          // top: "0",
          show: true,
          itemGap: 20,
          // icon: "circle",
           formatter: function (param) {
             let item = legendBfb.filter(item => item.name == param)[0];
             let bfs = that.fomatFloat(item.value * 100, 1) + "%";
             return `${item.name}: ${bfs}`;
           }
        },
        tooltip: {
          show: true,
          formatter: params => {
            if (params.seriesName !== 'mouseoutSeries' && params.seriesName !== 'pie2d') {
              return `${option.series[params.seriesIndex].pieData.name}<br/>` +
                `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>` +
                `${option.series[params.seriesIndex].pieData.value}`;
            }
          }
        },
        //这个可以变形
        xAxis3D: {
          min: -1,
          max: 1
        },
        yAxis3D: {
          min: -1,
          max: 1
        },
        zAxis3D: {
          min: -1,
          max: 1
        },
        grid3D: {
          show: false,
          boxHeight: boxHeight,
          left: '0%',
          top: '0',
          viewControl: {
            alpha: 30,
            distance: 160,
            // autoRotate: false,
            beta: -80,
          }
        },
        series: series
      };
      return option;
    },
    // 告诉函数
    getHeight3D(series, height) {
      series.sort((a, b) => {
        return (b.pieData.value - a.pieData.value);
      })
      return height * 25 / series[0].pieData.value;
    },
    // 计算方法
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
      let midRatio = (startRatio + endRatio) / 2;
      let startRadian = startRatio * Math.PI * 2;
      let endRadian = endRatio * Math.PI * 2;
      let midRadian = midRatio * Math.PI * 2;
      if (startRatio === 0 && endRatio === 1) {
        isSelected = false;
      }
      k = typeof k !== 'undefined' ? k : 1 / 3;
      let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
      let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
      let hoverRate = isHovered ? 1.05 : 1;
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32
        },
        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20
        },
        x: function (u, v) {
          if (u < startRadian) {
            return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          if (u > endRadian) {
            return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        y: function (u, v) {
          if (u < startRadian) {
            return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          if (u > endRadian) {
            return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u);
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u) * h * .1;
          }
          return Math.sin(v) > 0 ? 1 * h * .1 : -1;
        }
      };
    },
    // 百分比计算
    fomatFloat(num, n) {
      var f = parseFloat(num);
      if (isNaN(f)) {
        return false;
      }
      f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // n 幂
      var s = f.toString();
      var rs = s.indexOf('.');
      if (rs < 0) {
        rs = s.length;
        s += '.';
      }
      while (s.length <= rs + n) {
        s += '0';
      }
      return s;
    },
  },
}
</script>

<style scoped>
.pie3d {
  width: 100%;
  height: 100%;
}
</style>
