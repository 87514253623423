<template>
  <div class="newSeeds">
    <div class="title">
      <div class="title-top">
        <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="addMessage">新增用户</el-button>
        <div class="el-title-item">
          <el-form ref="form" :model="logisticsForm" label-width="80px">
            <el-form-item style="margin-left: 50vw" label="用户名">
              <el-input v-model="logisticsForm.name" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <!-- <el-form-item label="消息分组">
              <el-select v-model="logisticsForm.region" placeholder="请选择">
                <el-option :label="item.name" :value="item.id" v-for="(item, i) in groupingIndex" :key="i"></el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </div>
        <el-button style="margin-left: 2vw" type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
      </div>

    </div>
    <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
              @selection-change="handleSelectionChange">
      <el-table-column prop="userName" label="用户名" width="100" show-overflow-tooltip align="center"></el-table-column>
      <!-- <el-table-column prop="password" label="密码" show-overflow-tooltip align="center"> </el-table-column> -->
      <el-table-column prop="realName" label="姓名" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="身份标签" align="center">
        <template slot-scope="scope">
          {{ scope.row.label == 0 ? "用户" : "专家" }}
        </template>
      </el-table-column>
      <el-table-column prop="parentUser" label="上级账号" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="level" label="等级" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="tel" label="手机号" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="operation">
            <el-button type="primary" v-if="roleName == 'admin'" @click="handleSetTime(scope.row)" size="small"
                       icon="el-icon-setting"></el-button>
            <el-button @click="updateClick(scope.$index, scope.row)" type="primary" size="small"
                       icon="el-icon-edit"></el-button>
            <el-button @click="delList(scope.row)" type="primary" size="small" icon="el-icon-delete"></el-button>
            <el-button @click="eaitPass(scope.row)" type="primary" size="small" icon="el-icon-s-custom"></el-button>
            <el-button @click="resetPass(scope.row)" type="primary" size="small"
                       icon="el-icon-refresh-right"></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[8, 10, 50, 100, 150]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listTotal"
      >
      </el-pagination>
    </div>
    <!-- 编辑 -->
    <el-dialog title="" :visible.sync="updateForm" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative;">
        <span class="title-name"></span>
        <span class="title-age">{{ boxPrompt }}</span>
        <span style="position: absolute;top: 50%;transform: translateY(-50%);right: 0px;font-size: 2.22vh;">
          <!-- <i
            class="el-icon-close"
            style="color: #fff;margin-right:10px;cursor: pointer;"
            @click="updateForm = false"
          ></i> -->
        </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="upTableData" :rules="rules" :model="upTableData" label-width="10vw">
          <el-form-item v-if="boxPrompt == '新增用户'" label="账号" prop="userName">
            <el-input v-model.trim="upTableData.userName" placeholder="最低二位及以上"></el-input>
          </el-form-item>
          <el-form-item v-else-if="boxPrompt == '编辑用户信息'" label="账号" prop="userName">
            <el-tooltip content="账号不可修改" placement="top">
              <el-input v-model.trim="upTableData.userName" disabled placeholder="最低二位及以上"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="密码" prop="password" v-if="boxPrompt == '新增用户'">
            <el-input type="password" v-model.trim="upTableData.password" placeholder="最低六位及以上"></el-input>
          </el-form-item>
          <el-form-item label="选择角色" prop="role">
            <el-select v-model="upTableData.role" placeholder="请选择角色">
              <el-option :label="item.name" :value="item.id" v-for="(item, i) in roluList" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" prop="realName">
            <el-input v-model.trim="upTableData.realName"></el-input>
          </el-form-item>
          <!-- <el-form-item label="下单时间" prop="data4">
            <div class="block">
              <el-date-picker v-model="upTableData.data4" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间"> </el-date-picker>
            </div>
          </el-form-item> -->
          <el-form-item label="邮箱" prop="email">
            <el-input v-model.trim="upTableData.email"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="tel">
            <el-input v-model.trim="upTableData.tel"></el-input>
          </el-form-item>
          <el-form-item label="身份标签" prop="label">
            <el-select v-model="upTableData.label" placeholder="请选择身份">
              <el-option :label="item.name" :value="item.id" v-for="(item, i) in labelList" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="large">
            <!-- <el-button class="saveBtn" type="primary" @click="onSubmit">保存</el-button> -->
            <el-button class="saveBtn" type="primary" @click="saveForm('upTableData')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="passBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative;">
        <span class="title-name"></span>
        <span class="title-age">修改密码</span>
        <span style="position: absolute;top: 50%;transform: translateY(-50%);right: 0px;font-size: 2.22vh;"> </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="password" :rules="passRule" :model="eaitPassword" label-width="10vw">
          <el-form-item label="账号" prop="userName">
            <el-input v-model.trim="eaitPassword.userName" disabled placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="原密码" prop="oldPass">
            <el-input type="password" v-model.trim="eaitPassword.oldPass" placeholder="请输入原密码"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPass">
            <el-input type="password" v-model.trim="eaitPassword.newPass" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item label="确定新密码" prop="newPass2">
            <el-input type="password" v-model.trim="eaitPassword.newPass2" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item size="large">
            <el-button class="saveBtn" type="primary" @click="savePassword(eaitPassword, 'password')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!--有效期设置-->
    <el-dialog title="" :visible.sync="setTimeVisible" width="31vw" class="addAlameBox" >
      <div slot="title" class="header-title" style="position: relative;">
        <span class="title-name"></span>
        <span class="title-age">有效期设置</span>
        <span style="position: absolute;top: 50%;transform: translateY(-50%);right: 0px;font-size: 2.22vh;">
        </span>
      </div>
      <div class="addAlaForm"  v-loading="timeLoading">
        <el-form ref="setTime" :rules="timeRules" :model="setTimeForm" label-width="10vw">
          <el-form-item label="用户名">
            <el-input disabled v-model.trim="setTimeForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input disabled v-model.trim="setTimeForm.realName" ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input disabled v-model.trim="setTimeForm.tel"></el-input>
          </el-form-item>
          <el-form-item label="有效期限" prop="days">
            <el-input type="number" v-model.trim="setTimeForm.days"  placeholder="请输入有效期限"></el-input>
          </el-form-item>
          <el-form-item label="大屏名称"  prop="dpmc">
            <el-input v-model.trim="setTimeForm.dpmc" placeholder="请输入大屏名称"></el-input>
          </el-form-item>
          <el-form-item label="路径"  prop="path">
            <el-input v-model.trim="setTimeForm.path" placeholder="请输入路径"></el-input>
          </el-form-item>
          <el-form-item label="绑定单号"  prop="orderNo">
            <el-input v-model.trim="setTimeForm.orderNo" placeholder="请输入绑定单号"></el-input>
          </el-form-item>
          <el-form-item label="金额"  prop="amount">
            <el-input type="number" v-model.trim="setTimeForm.amount" placeholder="请输入金额"> <template slot="append">元</template></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea"  autosize v-model.trim="setTimeForm.remark"  placeholder="请输入备注"></el-input>
          </el-form-item>
          <!-- <el-form-item size="large">
            <el-button class="saveBtn" type="primary" @click="saveTimeSet()">保存</el-button>
          </el-form-item> -->
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setTimeVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveTimeSet()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8|9][0-9]{9}$/;
      if (!value) {
        return callback(new Error('手机号不可为空'));
      }
      if (!phoneReg.test(value)) {
        return callback(new Error('手机号不正确！'));
      }
      callback();
    };
    var checkAge2 = (rule, value, callback) => {
      const emailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error('邮箱不可为空'));
      }
      if (!emailReg.test(value)) {
        return callback(new Error('邮箱格式不正确！'));
      }
      callback();
    };
    var nameRule = (rule, value, callback) => {
      const emailReg = /^[0-9a-zA-Z_]{1,}$/;
      if (!value) {
        return callback(new Error('用户名不可为空'));
      }
      if (!emailReg.test(value)) {
        return callback(new Error('用户名不合法'));
      }
      callback();
    };
    var passwordRule = (rule, value, callback) => {
      const emailReg = /^[0-9a-zA-Z_]{1,}$/;
      if (!value) {
        return callback(new Error('密码不可为空'));
      }
      if (!emailReg.test(value)) {
        return callback(new Error('密码不合法'));
      }

      callback();
    };
    var passwordRule2 = (rule, value, callback) => {
      const emailReg = /^[0-9a-zA-Z_]{1,}$/;
      if (!value) {
        return callback(new Error('密码不可为空'));
      }
      if (!emailReg.test(value)) {
        return callback(new Error('密码不合法'));
      }
      if (value != this.eaitPassword.newPass) {
        return callback(new Error('两次密码输入不一致'));
      }
      callback();
    };
    return {
      upTableData: {
        userName: '',
        password: '',
        realName: '',
        tel: '',
        email: '',
        role: '',
      },
      upTableDatas: {
        userName: '',
        password: '',
        realName: '',
        tel: '',
        email: '',
        role: '',
      },
      labelList:[
        {
          name:"用户",
          id:0
        },
        {
          name:"专家",
          id:1
        },
      ],
      roluList: [],
      tableData: [],
      rules: {
        userName: [
          { required: true, message: '用户名不可为空', trigger: ['blur', 'change'] },
          { min: 2, max: 15, message: '长度在 2 到 10 个字符', trigger: ['blur', 'change'] },
          //   { pattern: /[/W]/g, message: '目前只支持中国大陆的手机号码' }
          { validator: nameRule, trigger: 'blur' },
        ],
        password: [
          { required: true, message: '密码不可为空', trigger: ['blur', 'change'] },
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: ['blur', 'change'] },
          { validator: passwordRule, trigger: 'blur' },
        ],
        realName: { required: true, message: '姓名不可为空', trigger: 'change' },
        //email: [{ required: false,message: '邮箱不能为空' }, { validator: checkAge2, trigger: 'blur' }],
        tel: [{ required: true,message: '手机号不能为空' }, { validator: checkAge, trigger: 'blur' }],
        // email: [

        //   { required: true, message: '邮箱不可为空', trigger: ['blur', 'change'] },
        //   { validator: checkAge2, trigger: ['blur', 'change'] },
        // ],
        // tel: [
        //   { required: true, message: '手机号不可为空', trigger: ['blur', 'change'] },
        //   { validator: checkAge, trigger: ['blur', 'change'] },
        // ],
        role: { required: true, message: '角色为必选项', trigger: ['blur', 'change'] },
      },
      passRule: {
        oldPass: [
          { required: true, message: '密码不可为空', trigger: 'blur' },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: ['blur', 'change'] },
          { validator: passwordRule, trigger: 'blur' },
        ],
        newPass: [
          { required: true, message: '密码不可为空', trigger: 'blur' },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: ['blur', 'change'] },
          { validator: passwordRule, trigger: 'blur' },
        ],
        newPass2: [
          { required: true, message: '密码不可为空', trigger: ['blur', 'change'] },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: ['blur', 'change'] },
          { validator: passwordRule2, trigger: ['blur', 'change'] },
        ],
      },
      setTimeVisible: false,
      setTimeForm: {
        userName: '',
        realName: '',
        tel: '',
        days: '',
        dpmc: '',
        path: '',
        remark: '',
        orderNo: '',
        amount: ''
      },
      timeRules: {
        days: [
          { required: true, message: '有效期限不可为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (/^(([1-9][0-9]*))$/.test(value) == false) {
                callback(new Error("请输入正整数"));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        dpmc: [
          { required: true, message: '大屏名称不可为空', trigger: ['blur', 'change'] },
        ],
        path: [
          { required: true, message: '路径不可为空', trigger: ['blur', 'change'] }
        ],
        orderNo: [
          { required: true, message: '绑定单号不可为空', trigger: ['blur', 'change'] },
        ],
        amount: [
          { required: true, message: '金额不可为空', trigger: ['blur', 'change'] },
        ],
      },
      timeLoading: false,
      passBox: false,
      eaitPassword: {},
      boxPrompt: '',
      nowIndex: 0,
      updateForm: false,
      currentPage4: 1,
      page: 1,
      size: 8,
      multipleSelection: [],
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      logisticsForm: {
        name: '',
        region: -1,
      },
      value2: '',
      page: 1,
      size: 8,
      listTotal: 0,
      loading: false,
      type: 23,
      searchStatus: false,
      passText: false,
    };
  },
  computed:{
    roleName(){
      return window.localStorage.getItem('user')
    }
  },
  mounted() {
    this.requrstList();
    this.roluReaurst();
  },
  methods: {
    //请求列表数据
    requrstList(where) {
      this.$get('/userManage/getUserList', { page: this.page, limit: this.size, likeName: where || '' }).then((res) => {
        if (res.data.state == 'success') {
          this.tableData = [];
          this.listTotal = res.data.count;
          res.data.datas.forEach((item) => {
            if (item.del !== 1) {
              this.tableData.push(item);
            }
          });
          //this.tableData = res.data.datas;
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    roluReaurst() {
      this.$get('/roleManage/getRoleListByPage', { page: 1, size: 1000, where: '', id: '' }).then((res) => {
        if (res.data.state == 'success') {
          this.roluList = res.data.datas;
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    updateClick(param1, param2) {
      //console.log(param2);
      this.boxPrompt = '编辑用户信息';
      this.updateForm = true;
      this.$nextTick(() => {
        this.upTableData = JSON.parse(JSON.stringify(param2));
      });
      //this.upTableData.level = Number(JSON.parse(JSON.stringify(param2.level)));
    },
    // 新增
    addMessage() {
      this.boxPrompt = '新增用户';
      this.updateForm = true;
      if (this.$refs['upTableData'] !== undefined) {
        this.$nextTick(() => {
          this.upTableData = this.upTableDatas;
          this.$refs['upTableData'].resetFields();
        });
      }
    },
    //删除
    delList(item) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$get('/user/deleteUser', { userName: item.userName }).then((res) => {
            //console.log(res);
            if (res.data.state == 'success') {
              this.$message.success('删除成功');
              this.requrstList();
            } else {
              this.$message.info(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    resetPass(value) {
      this.$confirm(`此操作将重置 ${value.userName} 用户密码为初始密码123456,是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$get('/bigData/getUserResetPass', { username: value.userName }).then((res) => {
            if (res.data.state == 'success') {
              this.$message({ type: 'success', message: '密码重置成功!' });
            } else {
              this.$message({ type: 'success', info: '请稍后重试!' });
            }
          });
        })
        .catch(() => {
          this.$message({ type: 'info', message: '已取消重置' });
        });
    },
    eaitPass(data) {
      //console.log(data);
      this.passBox = true;
      this.eaitPassword = JSON.parse(JSON.stringify(data));
    },

    savePassword(data, from) {
      let { newPass, oldPass, id } = data;
      this.$refs[from].validate((valid) => {
        if (valid) {
          this.$post('/bigData/updateMyInfo', { newPass, oldPass, id }).then((res) => {
            this.passBox = false;
            if (res.data.state == 'success') {
              this.$message.success('修改成功');
            } else {
              this.$message.info(res.data.msg);
            }
          });
        }
      });
    },
    //分组监听
    groupChange(val) {
      //console.log(val);
      this.upTableData.level = val;
    },
    /**
     * @description: 恢复数据
     * @param {*} data 数据
     * @return {*}
     */    
    recoveryUse(data){
        this.$get('/userManage/updateUserInfoTwo', data).then((res) => {
            //console.log(res)
            this.requrstList();
        })
    },
    //保存
    saveForm() {
      let url = '';
      let updata = this.upTableData;
      //   updata.type = this.type;
      if (this.boxPrompt == '编辑用户信息') {
        url = '/userManage/updateUserInfo';
      } else if (this.boxPrompt == '新增用户') {
        url = '/user/createUser';
      }
      this.$refs['upTableData'].validate((valid) => {
        if (valid) {
          this.$get(url, updata).then((res) => {
            // //console.log(res);
            if (res.data.state == 'success') {
              this.requrstList();
              this.$message.success('创建成功');
              this.updateForm = false;

            } else{
              this.$message.info(res.data.msg);
            }
          });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    saveTimeSet(){
      this.$refs['setTime'].validate(async(valid) => {
        if (valid) {
          const form  = this.setTimeForm
          await this.$postJSON('/largeScreenManagement/addOrUpdateLargeScreenManagement',{userName:form.userName,dpmc:form.dpmc,path:form.path})
          this.$postJSON('/userManage/updateValid', form).then((res) => {
            // //console.log(res);
            if (res.data.state == 'success') {
              this.$message.success('保存成功');
              this.setTimeVisible = false;
            } else{
              this.$message.info(res.data.msg);
            }
          });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 有效期设置
    handleSetTime(row){
      this.setTimeVisible = true
      this.$nextTick(()=>{
        this.$refs['setTime'].resetFields();
      })
      
      this.setTimeForm = {
        userName: row.userName,
        realName: row.realName,
        tel: row.tel,
        days: '',
        dpmc:'',
        path: '',
        remark:'',
        orderNo: '',
        amount: '',
      }
      this.timeLoading = true
      this.$get('/userManage/pageOrder', {
                    page: this.page,
                    size: 100,
                    userName: row.userName,
                    tel: row.tel,
                }).then((res)=>{
                  this.timeLoading = false
                  if (res.data.state == 'success') {
                    const resData = res.data.data
                    if (resData.length>0){
                      const data = resData[resData.length-1]
                      this.setTimeForm = {
                        userName: row.userName,
                        realName: row.realName,
                        tel: row.tel,
                        days: data.days,
                        dpmc: data.dpmc,
                        path: data.path,
                        remark: data.remark,
                        orderNo: data.orderNo,
                        amount: data.amount
                      }
                    }
                  }
                })
    },
    //刷新
    refresh() {
      this.loading = true;
      //   this.requrstList();
      if (!this.searchStatus) {
        this.requrstList();
      } else {
        this.search();
      }
    },
    //搜索
    search() {
      let name = this.logisticsForm.name;
      this.page = 1;
      //   let level = this.logisticsForm.region;
      //   if (level == -1) level = '';
      this.loading = true;
      this.searchStatus = true;
      if (name == '' || !name) {
        this.searchStatus = false;
      }
      this.requrstList(name);
    },
    //重置
    removeForm() {
      this.loading = true;
      this.logisticsForm.name = '';
      this.logisticsForm.region = -1;
      this.requrstList();
    },
    handleEdit() {},
    handleDelete() {},
    handleSizeChange(val) {
      this.size = val;
      this.requrstList();
      //console.log(val);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.requrstList();
    },
  },
};
</script>

<style scoped lang="less">
@import '../../../style/backTable.less';
.operation {
  display: flex;
  justify-content: center;
}
.newSeeds /deep/ .el-table__body-wrapper {
  // max-height: 73vh;
  max-height: 60vh;
}
.title{
  margin-bottom: 2vh;
}
/deep/ .el-dialog__body{
  height: 500px;
  overflow: auto;
}
/deep/ .el-dialog__footer{
  border-top: 1px solid #eee;
    padding: 20px;
}
/deep/ input::-webkit-input-placeholder {
  font-size: 1.5vh;
}
/deep/ textarea::-webkit-input-placeholder {
  font-size: 1.5vh;
}
</style>
