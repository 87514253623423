<template>
  <div class="container" id="contenta">
    <TableBox
      ref="tableBox"
      :tableHeader="tableHeader"
      :rowBtns="rowBtns"
      :tableParams="tableParams"
      request="/pro10/csgl/page"
      @addOption="addOption"
    >
<!--      <div slot="search-content" class="search-header-box flex_row">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="果农名称">
            <el-input v-model="form.gnmc" clearable></el-input>
          </el-form-item>
          <el-form-item label="果农电话">
            <el-input v-model="form.gndh" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="handleSearch"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
      </div>-->
    </TableBox>

    <UpdataModal
      ref="updata"
      :showFlag="showFlag"
      :messageBox="messageBox"
      :formTitle="formTitle"
      @close="closeModal"
      @success="refresh"
      :record="addForm"
    ></UpdataModal>
    <!-- 二维码 -->
    <el-dialog :modal="false" title="二维码" :visible.sync="codeShow" width="20%">
      <div v-loading="loadingCode" id="qrcode" ref="qrCodeUrl"></div>
<!--       <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary"
          >下载</el-button
          >
      </span>-->
    </el-dialog>
  </div>
</template>
<script>
import UpdataModal from "./modal/updata.vue";
import TableBox from "@/components/lxw/TableBox/index.vue";
import FormBox from "@/components/lxw/Form/index.vue";
import QRCode from "qrcodejs2";

export default {
  name: "lawsAReg",
  components: { UpdataModal, TableBox, FormBox },
  data() {
    return {
      loading: false,
      ids: 0,
      addForm: {},
      tableParams: {},
      form: {},
      tableHeader: [
        {
          label: "名称",
          key: "cpmc",
        },
        {
          label: "品种",
          key: "pz",
        },
        {
          label: "规格",
          key: "gg",
        },
        {
          label: "采收日期",
          key: "scrq",
        },
        {
          label: "所属企业",
          key: "qymc",
        },
        {
          label: "所属基地",
          key: "jdmc",
        },
      ],
      rowBtns: [
        {
          label: "二维码",
          type: "primary",
          size: "small",
          handler: (row) => {
            this.getErweima(row);
          },
        },
        {
          label: "编辑",
          type: "primary",
          size: "small",
          icon: "el-icon-edit",
          handler: (row) => {
            this.edits(row);
          },
        },
        {
          label: "删除",
          type: "danger",
          size: "small",
          icon: "el-icon-delete",
          handler: (row) => {
            this.deletes(row.id);
          },
        },
      ],

      formTitle: "",
      messageBox: false,
      showFlag: false,
      showSearchBtn: false,
      token: "",
      filesList: [],
      codeShow:false,
      loadingCode: true,
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem("token"));
  },
  methods: {
    //二维码
    getErweima(data) {
      let url = `https://cloud.half-half.cn/#/productH5?uuid=${data.uuid}`;
      this.urls = url;
      this.$nextTick(() => {
        this.codeShow = true;
        setTimeout(() => {
          this.$refs.qrCodeUrl.innerHTML = "";
          var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: url,
            width: 200,
            height: 200,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H
          });
        }, 200);
      });
    },
    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.addForm = JSON.parse(JSON.stringify(row));
      this.messageBox = true;
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/pro10/csgl/delete/${id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.$refs.tableBox.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },

    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;

      this.$nextTick(() => {
        this.addForm = {
          // title: 'biaoti'
        };
        this.$refs.updata.resetForm();
      });
    },

    // 搜索
    handleSearch() {
      this.tableParams = this.form
    },

    refresh() {
      this.messageBox = false;
      this.$refs.tableBox.getList();
    },

    closeModal() {
      this.messageBox = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-header-box {
  /* width: 90%; */
  display: flex;
}
.search-header-box ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
.search-header-box ::v-deep .el-form {
    display: flex;
    margin-right: 20px;
  }

  #contenta ::v-deep .title{
    justify-content: flex-start !important;
  }
</style>
