<template>
  <div class="container">
    <div class="nav">
      <p class="navTitle">设备列表</p>
      <p class="navList" v-for="(item, index) in navList" :class="index==deviceIndex?'active':''" :key="index"
         @click="deviceClick(index, item)">{{ item.deviceName }}</p>
    </div>
    <div class="main">
      <div class="mainHead">
        <div class="deviceBox">
          <div class="navTitle2">设备信息</div>
          <div class="deviceInfo">
            <div>设备名称：
              <span>{{ deviceInfos.deviceName }}</span>
            </div>
            <div>设备编号：
              <span>{{ deviceInfos.deviceNum }}</span>
            </div>
            <div>时间：
              <span>{{ deviceInfos.realTime }}</span>
            </div>
            <div>品牌：
              <span v-if="deviceInfos.brand == '1'">协议2.0</span>
              <span v-if="deviceInfos.brand == '0'">有人云</span>
              <span v-if="deviceInfos.brand == '2'">协议3.0</span>
            </div>
          </div>
        </div>
      </div>
      <div class="echart" id="echart">
        <div class="tablesBox">
          <div class="navTitle2">定时设置</div>
          <el-table :data="tableDataNew" empty-text="暂无数据" style="width: 100%" @expand-change="handleExpandChange">
            <el-table-column prop="type" label="名称"></el-table-column>
            <el-table-column prop="data" label="开关状态">
              <template slot-scope="scope">
                <el-switch
                    style="display: block;pointer-events: none;"
                    v-model="scope.row.data"
                    active-value="1"
                    inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="添加定时设置">
              <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-plus"
                    @click="edits(scope.$index, scope.row)"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table :data="scope.row.subData" empty-text="暂无数据" style="width: 100%;height: 40vh;">
                  <el-table-column prop="rwsj" label="任务时间"></el-table-column>
                  <el-table-column prop="cmd" label="开关操作">
                    <template slot-scope="scope">
                      <span v-if="scope.row.cmd == 0">闭合继电器</span>
                      <span v-if="scope.row.cmd == 1">断开继电器</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="rwlx" label="任务类型">
                    <template slot-scope="scope">
                      <span v-if="scope.row.rwlx == 'once'">执行一次</span>
                      <span v-if="scope.row.rwlx == 'dayOnce'">每日一次</span>
                      <span v-if="scope.row.rwlx == 'monthOnce'">每月一次</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="createTime" label="创建日期"></el-table-column>
                  <el-table-column prop="type" label="操作">
                    <template slot-scope="scope">
                      <el-button
                          type="primary"
                          size="small"
                          icon="el-icon-edit"
                          @click="editNew(scope.row)"
                      ></el-button>
                      <el-button
                          type="primary"
                          size="small"
                          icon="el-icon-delete"
                          @click="deletes(scope.row)"
                      ></el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 添加定时设置 -->
    <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
          <el-form-item label="任务名称" prop="jobName">
            <el-input v-model="addForm.jobName" placeholder="请输入任务名称"></el-input>
          </el-form-item>
          <el-form-item label="执行操作" prop="cmd">
            <el-select v-model="addForm.cmd" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务类型" prop="rwlx">
            <el-select v-model="addForm.rwlx" placeholder="请选择">
              <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="addForm.rwlx == 'once'" label="任务时间" prop="rwsj">
            <el-date-picker @change="getTime" v-model="addForm.rwsj" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                            placeholder="选择任务时间"></el-date-picker>
          </el-form-item>
          <el-form-item v-if="addForm.rwlx == 'dayOnce'" label="任务时间" prop="rwsj">
            <el-time-picker @change="getTime2" v-model="addForm.rwsj" format="HH:mm:ss" value-format="HH:mm:ss"
                            placeholder="选择任务时间"></el-time-picker>
          </el-form-item>
          <el-form-item v-if="addForm.rwlx == 'monthOnce'" label="任务时间" prop="rwsj">
            <el-date-picker @change="getTime3" v-model="addForm.rwsj" format="dd HH:mm:ss" value-format="dd HH:mm:ss"
                            type="datetime" placeholder="选择任务时间"></el-date-picker>
          </el-form-item>
<!--          <el-form-item label="延迟n秒后执行" prop="delayCron">
            <el-input v-model="addForm.delayCron" type="number" placeholder="请输入延迟n秒后执行">
              <i slot="append">秒</i>
            </el-input>
          </el-form-item>-->
          <el-form-item label="通知手机号" prop="tel">
            <el-input v-model="addForm.tel" type="number" placeholder="请输入通知手机号"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
export default ({
  data() {
    return {
      value1: [],
      navList: [],
      deviceIndex: 0,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      page: 1,
      limit: 10,
      total: 0,
      deviceNumber: '',
      tableDatas: [],
      deviceInfos: {},
      tableDataNew: [],
      rules: {
        jobName: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        cmd: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        /*delayCron: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],*/
        rwlx: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        rwsj: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        tel: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
      },
      formTitle: "",
      messageBox: false,
      showFlag: false,
      addForm: {},
      devIndex: 1,
      options: [
        {name: '闭合继电器', value: 0},
        {name: '断开继电器', value: 1}
      ],
      options2: [
        {name: '执行一次', value: 'once'},
        {name: '每日一次', value: 'dayOnce'},
        {name: '每月一次', value: 'monthOnce'},
      ],
      subData: [],
      data1: 1,
    }
  },
  mounted() {
    this.getDeviceList();
  },
  methods: {
    deviceClick(index, val) {
      this.deviceIndex = index;
      this.deviceNumber = val.deviceNum;
      this.deviceInfos = val;
      this.tableDataNew = [];

      let datas = val.realData.replace(/null\|/g, '').replace(/\|null/g, '').replace(/0\.00/g, "关").replace(/1\.00/g, "开");
      let datas2 = datas.split("|");
      let data1 = [];
      let data2 = [];
      for (let a = 0; a < datas2.length; a++) {
        data1.push(datas2[a].split("·")[0]);
        data2.push(datas2[a].split("·")[1]);
      }
      for (let x = 0; x < data1.length; x++) {
        this.tableDataNew.push({type: data1[x], data: data2[x]})
      }
      this.getCollapseClick();
      console.log(this.tableDataNew)
    },
    handleExpandChange(row) {
      // this.getCollapseClick();
    },
    // 折叠列表
    getCollapseClick() {
      this.$get('/pro12/dwjob/page', {

        page: 1,
        size: 1000,
        deviceNum: this.deviceNumber

      }).then((res) => {
        if (res.data.state == 'success') {
          this.subData = res.data.data;
          for (let a = 0; a < this.tableDataNew.length; a++) {
            this.$set(this.tableDataNew[a], 'subData', [])
          }
          for (let a = 0; a < res.data.data.length; a++) {
            this.tableDataNew[res.data.data[a].relayId - 1].subData.push(res.data.data[a])
          }
          console.log(this.tableDataNew)
        }
      })
    },
    // 添加定时设置
    edits(index, row) {
      this.devIndex = index + 1;
      this.showFlag = true;
      this.formTitle = "添加定时设置";
      this.messageBox = true;
      this.$nextTick(() => {
        this.addForm = {
          rwlx: 'once'
        };
        this.$refs.addForm.resetFields();
      });
    },
    // 编辑
    editNew(row) {
      this.ids = row.id;
      this.showFlag = true;
      this.formTitle = "编辑";
      this.messageBox = true;
      this.$nextTick(() => {
        this.addForm = JSON.parse(JSON.stringify(row));
      });
    },
    // 删除
    deletes(row) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$delete(`/pro12/dwjob/delete/${row.id}`).then((res) => {
              if (res.data.state == "success") {
                this.$message.success("删除成功");
                this.getCollapseClick();
              } else {
                this.$message.error("删除失败");
              }
            });
          })
          .catch((e) => e);
    },
    getTime(val) {
      this.addForm.cron = this.generateCronExpression('once', val);
      console.log(this.addForm.cron)
    },
    getTime2(val) {
      console.log(val)
      this.addForm.cron = this.generateCronExpression('dayOnce', val);
      console.log(this.addForm.cron)
    },
    getTime3(val) {
      console.log(val)
      this.addForm.cron = this.generateCronExpression('monthOnce', val, val.split(" ")[0]);
      console.log(this.addForm.cron)
    },

    generateCronExpression(frequency, selectedTime, monthlyDate) {
      let time = selectedTime.split(":");
      let s = time[2];
      let m = time[1];
      let h = time[0];
      let cronExpression = "";
      if (frequency === "none") {
        // 暂不设置
        cronExpression = "";
      } else if (frequency === "once") {
        let oneTime = selectedTime.split(" ")[0];
        let twoTime = selectedTime.split(" ")[1];
        let time1 = oneTime.split("-");
        let time2 = twoTime.split(":");
        let s1 = time2[2];
        let m1 = time2[1];
        let h1 = time2[0];
        let d1 = time1[2];
        let mon1 = time1[1];
        let y1 = time1[0];
        // 一次运行的逻辑
        cronExpression =
            s1 +
            " " +
            m1 +
            " " +
            h1 +
            " " +
            d1 +
            " " +
            mon1 +
            " " +
            "?" +
            " " +
            //"*"//每年执行，之后这里要改
            y1
        ;
      } else if (frequency === "dayOnce") {
        // 每天运行的逻辑，根据选定的时间生成 Cron 表达式
        if (selectedTime) {
          cronExpression =
              s +
              " " +
              m +
              " " +
              h +
              " * * ?";
        }
      } else if (frequency === "monthOnce") {
        // 每月运行的逻辑，根据选定的日期（monthlyDate）和时间生成 Cron 表达式 
        let timeMonth = selectedTime.split(" ")[1];
        let ss = timeMonth[2];
        let mm = timeMonth[1];
        let hh = timeMonth[0];
        if (selectedTime) {
          cronExpression =
              ss +
              " " +
              mm +
              " " +
              hh +
              " " +
              monthlyDate +
              " * ?";
        }
      }
      return cronExpression;
    },
    getDeviceList() {
      let token = JSON.parse(window.localStorage.getItem("token"));
      this.$get('/deviceManage/getDeviceInfos', {

        page: 1,
        size: 1000,

      }).then((res) => {
        if (res.data.state == 'success') {
          this.navList = res.data.datas;
          this.deviceInfos = res.data.datas[0];
          let datas = res.data.datas[0].realData.replace(/null\|/g, '').replace(/\|null/g, '').replace(/0\.00/g, "关").replace(/1\.00/g, "开");
          let datas2 = datas.split("|");
          let data1 = [];
          let data2 = [];
          for (let a = 0; a < datas2.length; a++) {
            data1.push(datas2[a].split("·")[0]);
            data2.push(datas2[a].split("·")[1]);
          }
          for (let x = 0; x < data1.length; x++) {
            this.tableDataNew.push({type: data1[x], data: data2[x]})
          }
          this.tableDataNew.subData = [];
          this.deviceNumber = res.data.datas[0].deviceNum;
          this.getCollapseClick();
        }
      })
    },
    // 确认提交
    saveForm(formName) {
      this.addForm.id = this.ids;
      if (this.formTitle == '添加定时设置') {
        delete this.addForm.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$postJSON('/pro12/dwjob/save', {

            jobName: this.addForm.jobName,
            cmd: this.addForm.cmd,
            delayCmd: this.addForm.cmd,
            delayCron: this.addForm.delayCron,
            rwlx: this.addForm.rwlx,
            rwsj: this.addForm.rwsj,
            tel: this.addForm.tel,
            deviceNum: this.deviceInfos.deviceNum,
            relayId: this.devIndex,
            cron: this.addForm.cron,

          }).then((res) => {
            if (res.data.state == 'success') {
              if (this.formTitle == '添加定时设置') {
                this.$message.success('添加成功')
                this.getCollapseClick();
              } else {
                this.$message.success('修改成功')
                this.getCollapseClick();
              }
              this.messageBox = false
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 菜单点击事件
    handleNodeClick(e) {
      if (e.num) {
        this.deviceNumber = e.num

      }
    },

  }
})
</script>
<style scoped>
.container {
  position: relative;
  width: 100%;
  height: 92vh;
  overflow: hidden;
  padding-left: 1vh;
  box-sizing: border-box;
  display: flex;
}

.nav {
  display: inline-block;
  width: 12vw;
  height: 85vh;
  margin-top: 1vh;
  margin-left: 1vh;
  background: #fff;
  border-radius: 10px;
  vertical-align: top;
  overflow-y: auto;
}

.navList {
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  text-align: center;
  cursor: pointer;
  margin-top: 1vh;
  background: rgb(224, 239, 247);
}

.navList.active {
  /* color: #009688; */
  background: #999;
}

.nav::-webkit-scrollbar {
  display: none
}

.navTitle {
  height: 4vh;
  line-height: 4vh;
  margin-top: 2vh;
  font-size: 1.8vh;
  color: black;
  padding-left: 1vh;
  box-sizing: border-box;
  background: rgb(150, 229, 253);
}

.el-tree {
  margin-top: 1vh;
}

.main {
  display: inline-block;
  width: 72vw;
  height: 85vh;
  margin-left: 1.5vh;
  margin-top: 1vh;
  background: #fff;
  border-radius: 10px;
  vertical-align: top;
  padding: 1vh;
  box-sizing: border-box;
}

.mainHead {
  width: 100%;
  /* height: 10vh; */
}

.navTitle2 {
  width: 5vw;
  height: 4vh;
  line-height: 4vh;
  font-size: 1.8vh;
  color: black;
  /* padding-left: 1vh; */
  margin-top: 1vh;
  box-sizing: border-box;
  background: rgb(150, 229, 253);
  text-align: center;
}

.deviceInfo {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 1.8vh;
}

.mainHeadLeft {
  display: inline-block;
  width: 60%;
  box-sizing: border-box;
}

.mainHeadRight {
  display: inline-block;
  width: 39%;
  text-align: right;
  vertical-align: middle;
}

.mainHeadRight i {
  font-size: 1.8vh;
}

.el-pagination {
  margin-top: 2vh;
}

#echart {
  width: 100%;
  height: 78vh;
  margin-top: 3vh;
}

#echart /deep/ .el-table__body-wrapper {
  height: 72vh;
  overflow-y: auto;
}

.tablesBox {
  /* width: 45%; */
  width: 100%;
  height: 100%;
}

/*#echart /deep/.el-switch__core{
  background: #ff4949;
}
#echart /deep/.el-switch__core .is-checked .el-switch__core__button {
  background-color: #13ce66; !* 开启时的按钮背景色 *!
}
.o{
  background-color: #13ce66 !important; !* 开启时的按钮背景色 *!
}
#echart /deep/.el-switch__core .is-checked:not(.is-disabled) .el-switch__core__button {
  background-color: #ff4949; !* 关闭时的按钮背景色 *!
}*/
</style>
<style>
.container .el-table td, .container .el-table th {
  text-align: center !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}
</style>
