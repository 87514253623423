import { render, staticRenderFns } from "./updata.vue?vue&type=template&id=5b4bc336&scoped=true"
import script from "./updata.vue?vue&type=script&lang=js"
export * from "./updata.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b4bc336",
  null
  
)

export default component.exports