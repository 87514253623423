<template>
  <div class="newSeeds">
    <div class="title">
      <div class="title-top">
        <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="addMessage">角色创建</el-button>
        <!-- <div class="el-title-item">
          <el-form ref="form" :model="logisticsForm" label-width="80px">
            <el-form-item label="产品名称">
              <el-input v-model="logisticsForm.name" placeholder="请输入产品名称"></el-input>
            </el-form-item>
            <el-form-item label="消息分组">
              <el-select v-model="logisticsForm.region" placeholder="请选择">
                <el-option :label="item.name" :value="item.id" v-for="(item, i) in groupingIndex" :key="i"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div> -->
      </div>
      <div class="title-bottom">
        <!-- <div class="title-bottom-left">
          <el-button @click="refresh" icon="el-icon-refresh-right" size="small">刷新</el-button>
        </div> -->
        <div class="title-bottom-right">
          <!-- <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button> -->
          <!-- <el-button icon="el-icon-refresh" size="small" @click="removeForm">重置</el-button> -->
        </div>
      </div>
    </div>
    <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column prop="name" label="角色名称" width="100" show-overflow-tooltip align="center"> </el-table-column>
      <el-table-column prop="descr" label="角色描述" show-overflow-tooltip align="center"> </el-table-column>
       <el-table-column prop="level" label="角色等级" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="operation">
            <el-button @click="roleBut(scope.$index, scope.row)" type="primary" size="small" icon="el-icon-s-check"></el-button>
            <el-button @click="updateClick(scope.$index, scope.row)" type="primary" size="small" icon="el-icon-edit"></el-button>
            <el-button @click="delList(scope.row)" type="primary" size="small" icon="el-icon-delete"></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[8, 10, 50, 100, 150]"
        :page-size="8"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listTotal"
      >
      </el-pagination>
    </div>
    <!-- 编辑 -->
    <el-dialog title="" :visible.sync="updateForm" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative;">
        <span class="title-name"></span>
        <span class="title-age">{{ boxPrompt }}</span>
        <span style="position: absolute;top: 50%;transform: translateY(-50%);right: 0px;font-size: 2.22vh;">
          <!-- <i
            class="el-icon-close"
            style="color: #fff;margin-right:10px;cursor: pointer;"
            @click="updateForm = false"
          ></i> -->
        </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="upTableData" :rules="rules" :model="upTableData" label-width="10vw">
          <el-form-item label="角色名称" prop="name">
            <el-input v-model.trim="upTableData.name" placeholder="请输入角色名称"></el-input>
          </el-form-item>
          <el-form-item label="角色描述" prop="descr">
            <el-input type="textarea" v-model.trim="upTableData.descr" placeholder="请输入角色描述"></el-input>
          </el-form-item>
          <el-form-item label="角色等级" prop="level">
            <el-select v-model="upTableData.level" placeholder="请选择角色等级">
              <el-option :label="item.name" :value="item.level" v-for="(item, i) in regionData" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <!-- v-if="boxPrompt == '编辑角色信息'" -->

          <!-- <el-form-item label="下单时间" prop="data4">checkStatus
            <div class="block">
              <el-date-picker v-model="upTableData.data4" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间"> </el-date-picker>
            </div>
          </el-form-item> -->

          <el-form-item size="large">
            <!-- <el-button class="saveBtn" type="primary" @click="onSubmit">保存</el-button> -->
            <el-button class="saveBtn" type="primary" @click="saveForm('upTableData')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 权限 -->
    <el-dialog title="" @close="roleClose" :visible.sync="roleBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative;">
        <span class="title-name"></span>
        <span class="title-age">权限分配</span>
        <span style="position: absolute;top: 50%;transform: translateY(-50%);right: 0px;font-size: 2.22vh;">
          <!-- <i
            class="el-icon-close"
            style="color: #fff;margin-right:10px;cursor: pointer;"
            @click="updateForm = false"
          ></i> -->
        </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="" :model="roleRules" label-width="10vw">
          <el-form-item label="权限选择">
            <el-tree
              :data="datapath"
              accordion
              show-checkbox
              ref="tree"
              node-key="id"
              :default-checked-keys="checkedList"
              :default-expanded-keys="expandedList"
              :props="defaultProps"
              @current-change="handleCurrentChangse"
              @check="
                (click, checked) => {
                  checkStatus(click, checked);
                }
              "
              @check-change="handleCheckChange"
            >
            </el-tree>
          </el-form-item>
          <el-form-item>
            <el-button class="saveBtn" type="primary" @click="roleSave()">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { datapath } from '../../../components/rolePath.js';
import { historyRouter } from '../../../router';
export default {
  data() {
    return {
      //   datapath: datapath,
      datapath: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      upTableData: {
        name: '',
        descr: '',
        level: '',
      },
      tableData: [],
      roleRules: {},
      regionData: [
        /*{ name: '超级管理员', level: 0 },
        { name: '系统管理员', level: 1 },*/
        {name: '一级用户', level: 2},
        {name: '二级用户', level: 3},
      ],
      rules: {
        name: [
          { required: true, message: '角色名称不可为空', trigger: ['blur', 'change'] },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: ['blur', 'change'] },
        ],
        // data2: [{ required: true, message: '密码不可为空', trigger: ['blur', 'change'] }],
        level: { required: true, message: '角色等级不可为空', trigger: 'change' },
        descr:[
          { required: true, message: '角色描述不可为空', trigger: ['blur', 'change'] },
          { min: 2, max: 127, message: '长度在 2 到 127 个字符', trigger: ['blur', 'change'] },
        ]
      },
      boxPrompt: '',
      nowIndex: 0,
      updateForm: false,
      currentPage4: 1,
      multipleSelection: [],
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      logisticsForm: {
        name: '',
        region: -1,
      },
      value2: '',
      page: 1,
      size: 8,
      listTotal: 0,
      searchStatus: false,
      checkedData: [],
      roleBox: false,
      checkedList: [],
      expandedList: [],
      loading: false,
      saveThrottle:null
    };
  },
  mounted() {
    this.requrstList();
    this.menuData();
    this.saveThrottle =this.throttle(this.implement,3000);
    // this.userRequrst();
  },
  methods: {
    //请求列表数据
    requrstList(where, id) {
      this.$get('/roleManage/getRoleListByPage', { page: this.page, size: this.size, where: where || '', id: id || '' }).then((res) => {
        //console.log(res);
        if (res.data.state == 'success') {
          this.listTotal = res.data.count;
          this.tableData = res.data.datas;
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    pathOldData(id) {
      let _this = this;
      this.$get('/rights/getRoleRight', { id: id }).then((res) => {
        // //console.log(res);
        if (res.data.state == 'success') {
          this.menuData();
          if (res.data.data.list1 && res.data.data.list1.length > 0) {
            this.$nextTick(() => {
              //   _this.checkedList = res.data.data.list1; //选中
              _this.checkedList = res.data.data.list1; //选中
              _this.expandedList = res.data.data.list;
            });
          } else {
            this.$nextTick(() => {
              _this.checkedList = [];
              _this.expandedList = [];
              _this.checkedData = [];
            });
            _this.menuData();
          }
        }
      });
    },

    handleCheckChange(data, checked, indeterminate) {
      //   //console.log(data);
      // //console.log(checked);
      // //console.log(indeterminate);
      //   //console.log(data);
    },
    checkStatus(data, checked) {
      //console.log(checked);
      //   this.checkedData = checked.halfCheckedKeys.push(checked.checkedKeys);
      //   //console.log(checked.halfCheckedKeys.concat(checked.checkedKeys));
      checked.halfCheckedKeys.push.apply(checked.halfCheckedKeys, checked.checkedKeys);
      this.checkedData = checked.halfCheckedKeys;
      //   //console.log(data);
      //   //console.log(checked);
      //   this.checkedData = [];
      //   checked.checkedNodes.forEach((item) => {
      //     if (item.children) {
      //       this.checkedData.push({
      //         one: item,
      //       });
      //     }
      //   });
      //   //console.log(this.checkedData);
      //   this.checkedData =checked.checkedNodes
    },
    roleSave() {
      //if (!this.checkedData || this.checkedData.length == 0) return this.$message.info('请选择分类');
      //   if (this.checkedData || this.checkedData.length == 0) {
      //     this.checkedData.push(this.checkedList);
      //   }checkedList
      if (this.checkedData.length == 0) {
        // this.checkedData = this.checkedList;
        this.checkedData = this.expandedList;
      }
      let rights = this.checkedData.join(',');
      let roleId = this.upTableData.id;
      this.$get('/rights/updateAuth', { roleId, rights,type:0 }).then((res) => {//type 0增加 1删除
        // //console.log(res);
        this.roleBox = false;
        if (res.data.success) {
          this.roleButSave();
        } else {
          this.$message.info('操作失败，请稍后重试！');
        }
      });
    },

    // 请求页面权限
    // userRequrst() {
    //   this.$get('/rights/getRightById', { username: 'zhangjing' }).then((res) => {
    //     //console.log(res, 'user用户页面');
    //   });
    // },
    menuData() {
      this.$get('/rights/getAllRights').then((res) => {
        // //console.log(res, '454545455');
        this.datapath = res.data.datas;
      });
    },
    handleCurrentChangse(data, data1) {
      //console.log(data);
      //console.log(data1);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    updateClick(param1, param2) {
      //console.log(param2);
      this.boxPrompt = '编辑角色信息';
      this.updateForm = true;
      this.$nextTick(() => {
        this.upTableData = JSON.parse(JSON.stringify(param2));
      });

      //this.upTableData.level = Number(JSON.parse(JSON.stringify(param2.level)));
    },
    // 新增
    addMessage() {
      this.boxPrompt = '角色创建';
      this.updateForm = true;
      //   if (this.$refs['upTableData'] !== undefined) {
      this.$nextTick(() => {
        this.upTableData.descr = '';
        this.$refs['upTableData'].resetFields();
      });
      //   }
    },
    //删除
    delList(item) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$get('/roleManage/delUserList', { id: item.id }).then((res) => {
            if (res.data.state == 'success') {
              this.$message.success('删除成功');
              this.requrstList();
            } else {
              this.$message.info(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    //分组监听
    groupChange(val) {
      //console.log(val);
      this.upTableData.level = val;
    },
    //保存
    saveForm() {
      this.saveThrottle();
    },
    //执行
    implement() {
      let url = '/roleManage/saveRole';
      let updata = this.upTableData;
      //   updata.type = this.type;
      if (this.boxPrompt == '角色创建') {
        //   url = '/monitor/update';
        updata.id = 0;
      }
      // else if (this.boxPrompt == '角色创建') { }
      //   //console.log(updata);
      this.$refs['upTableData'].validate((valid) => {
        if (valid) {
          this.$get(url, updata).then((res) => {
            this.updateForm = false;
            //console.log(res);
            if (res.status == '200') {
              this.requrstList();
              this.roleButSave();
              this.$message.success('操作成功');
            } else {
              this.$message.error('操作失败！请稍后重试！');
            }
          });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    throttle(fn,delay) {
      let timeout ,flag=false;
      return function() {
        let args = arguments;
        if(!flag){
          flag = true;
          fn.apply(this,args);
          timeout && clearTimeout(timeout);
          timeout = setTimeout(()=>{
            flag = null;
          },delay)
        }
      }
    },
    //权限盒子
    roleBut(data1, data) {
      //   this.upTableData = JSON.parse(JSON.stringify(data));
      this.upTableData.id = JSON.parse(JSON.stringify(data)).id;
      this.pathOldData(data.id);
      const loadings = this.$loading({
        lock: true,
        text: '请求数据中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)',
      });
      setTimeout(() => {
        loadings.close();
        this.roleBox = true;
      }, 1000);
    },
    roleButSave() {
      this.userRequrst();
      const loadings = this.$loading({
        lock: true,
        text: '保存配置中，请稍后！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)',
      });
      setTimeout(() => {
        loadings.close();
        // this.$message.success('操作成功');
        // this.roleBox = true;
      }, 1000);
    },
    userRequrst() {
      let _this = this;
      let username = window.localStorage.getItem('user');
      _this.$get('/rights/getRightById', { username, type: 0 }).then((res) => {
        // //console.log(res, 'userRequrst');
        // if (res.data.state == 'success') {
        //   //console.log(res.data.datas);
        //   window.localStorage.setItem('userPath', JSON.stringify(res.data.datas));
        //   _this.$store.commit('setUserPath', res.data.datas);
        //   historyRouter(res.data.datas);
        // }
        let data = [];
          if (res.data.state == 'success' && res.data.datas.length) {
              data = res.data.datas[0].children ? res.data.datas[0].children : res.data.datas[0];
              window.localStorage.setItem('userPath', JSON.stringify(data));
              this.$store.commit('setUserPath', data);
              historyRouter(data);
          } else {
              window.localStorage.setItem('userPath', JSON.stringify(data));
              this.$store.commit('setUserPath', data);
              historyRouter(data);
          }
      });
    },
    roleClose() {},
    //刷新
    refresh() {
      this.loading = true;
      this.requrstList();

      //   this.requrstList();
      //   if (!this.searchStatus) {
      //     this.requrstList();
      //   } else {
      //     this.search();
      //   }
    },
    //搜索
    search() {
      let name = this.logisticsForm.name;
      //   let level = this.logisticsForm.region;
      //   if (level == -1) level = '';
      this.loading = true;
      this.searchStatus = true;
      if (name == '' || !name) {
        this.searchStatus = false;
      }
      this.requrstList(name);
    },
    //重置
    removeForm() {
      this.loading = true;
      this.logisticsForm.name = '';
      this.logisticsForm.region = -1;
      this.requrstList();
    },
    handleEdit() {},
    handleDelete() {},
    handleSizeChange(val) {
      //console.log(val);
      this.size = val;
      this.requrstList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.requrstList();
    },
  },
};
</script>

<style scoped lang="less">
@import '../../../style/backTable.less';
.operation {
  display: flex;
  justify-content: center;
}
.newSeeds /deep/ .el-table__body-wrapper{
        max-height: 70vh;
}
</style>
<style>
  .el-tooltip__popper{
    max-width: 40%!important;
  }
</style>
